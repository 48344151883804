import {
  deleteCollaborationRequest,
  mapBulkCreateOrUpdateDatasourceDataProperty,
  mapQueryCollaboratorTicketDetailsById,
  mapQueryProcessOrTemplateById,
  mapQueryProcesses,
  mapQueryRopaDataFlow,
  mapQueryRopaProcessCards,
  mapQueryRopaProcessReviewSchedules,
  mapQueryTotalRopaProcesses,
  mapRetentionPeriod,
  mutationReassignTicket,
  mutationRejectTicket,
  mutationRemoveRopaDataFlow,
  mutationRevokeAssignment,
  mutationSaveRopaDataFlow,
  mutationSendReminder,
  mutationUpdateProcessSettings,
  mutationUpdateTicketsStatus,
  queryAddCollaborators,
  queryBulkCreateOrUpdateDatasourceDataProperty,
  queryCollaboratorTicketDetailsById,
  queryCreateRetentionPeriod,
  queryCreateRopaProcess,
  queryCreateRopaTemplate,
  queryDeleteProcesOrTemplate,
  queryListProcesses,
  queryProcessOrTemplateById,
  queryRopaDataFlow,
  queryRopaProcessCards,
  queryRopaProcessReviewSchedules,
  queryTicketsForProcessOrTicket,
  queryTotalRopaProcesses,
  queryUpdateCollaborationTicket,
  queryUpdateCollaborator,
  queryUpdateRopaTemplate,
  sendRopaNewCollaborationRequest
} from './queriesv2'
import {
  mapQueryRopaOverviewAttributesInstanceCount,
  mapQueryRopaAttributes,
  queryRopaAttributes
} from './queries'
import { updateQuestionStatsAndReturnProcess } from './ropaUtil'
import { RopaCollaboration } from './ropaSlice'
import { collateCollaboratorResponses, getCompactCollaboratorDetails } from './collaboratorUtils'
import { DATA_SOURCE_TYPES, DataSourceProcessingStages, PAGE } from '../../constants'
import graphqlService from '../../services/graphqlService'
import { SortParams, defaultSortParams } from '../../utils/sortUtil'
import { AttributeSet, AttributeSetAttribute } from '../attributes/attributesSlice'
import { DownloadListParams, FilterParams } from '../../interfaces'
import apiService from '../../services/api/apiService'
import { PiaProcessGroupCard } from '../pia/piaSlice'
import { Take } from '../../utils/types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { uniq } from 'underscore'

export enum DataSourceDataPropertyFieldTypeEnum {
  purpose = 'PURPOSE',
  lawfulBasis = 'LAWFUL_BASIS',
  dataSubjects = 'DATA_SUBJECT',
  specialDataSubjects = 'SPECIAL_DATA_SUBJECT',
  processGroups = 'PROCESS_GROUPS',
  safeguards = 'SAFEGUARDS'
}
export const CUSTOM_TOPIC: BaseRopaQuestionnaire = {
  name: 'Custom topic',
  description: '',
  optional: true,
  questions: []
}

export enum RopaDefineProcessSteps {
  processDetails = 1,
  dataElements,
  dataSubjects,
  dataRetention,
  safeguards,
  transfers,
  customTopics
}

export enum RopaSystemTopics {
  processDetails = 'processDetails',
  dataElements = 'dataElements',
  dataSubjects = 'dataSubjects',
  dataRetention = 'dataRetention',
  safeguards = 'safeguards',
  transfer = 'transfers'
}

export const CustomTopicKey = 'customTopics'

export enum RopaConfigAction {
  createProcess = 'createProcess',
  updateProcess = 'updateProcess',
  cloneProcess = 'cloneProcess',
  updateProcessWithActions = 'updateProcessWithActions',
  viewProcess = 'viewProcess',
  createTemplate = 'createTemplate',
  updateTemplate = 'updateTemplate',
  cloneTemplate = 'cloneTemplate',
  viewTemplate = 'viewTemplate',
  viewCollaborators = 'viewCollaborators'
}

export enum RopaInputType {
  MultipleChoice = 'MULTIPLE',
  SingleChoice = 'SINGLE_CHOICE',
  Text = 'SINGLE',
  TextArea = 'DESCRIPTIVE'
}

export enum ProcessorEnum {
  Controller = 'CONTROLLER',
  JointController = 'JOINT_CONTROLLER',
  Processor = 'PROCESSOR',
  SubProcessor = 'SUB_PROCESSOR'
}

export enum ProcessingActivityRoleTypes {
  organization = 'ORGANISATION',
  dpo = 'DPO',
  representative = 'REPRESENTATIVE'
}

export enum TicketStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
  Revoked = 'REVOKED',
  Resent = 'RESENT',
  Submitted = 'SUBMITTED',
  Complete = 'COMPLETE'
}

export type RopaQuestion = {
  question: string
  options?: string[]
  responseType: RopaInputType
  questionResponse?: string[]
  questionId?: string
  isMandatory: boolean
}

export interface NameIdSummary {
  id: string
  name: string
  type?: string
}

export type FieldProperty = {
  key: string
  value: string[]
}

export type BaseCollaboratorDetails = {
  email: string
  status: string
}
export interface BaseRopaQuestionnaire {
  questions?: RopaQuestion[]
  questionIds?: string[]
  id?: string
  optional: boolean
  name?: string
  description?: string
  processQuestionId?: string
  totalQuestions?: number
  answeredQuestions?: number
  collaboratorDetails?: BaseCollaboratorDetails[]
}

export interface ProcessDetailsQuestionnaire extends BaseRopaQuestionnaire {
  nameProperties: FieldProperty[]
  descriptionProperties: FieldProperty[]
  purposeProperties: FieldProperty[]
  processGroupsProperties: FieldProperty[]
  lawfulBasisProperties: FieldProperty[]
  automatedProcessingProperties: FieldProperty[]
  automatedProcessingDescriptionProperties: FieldProperty[]
  processDetailsProperties: FieldProperty[]
  processDetailsQuestionProperties: {
    nameProperties: FieldProperty[]
    emailProperties: FieldProperty[]
    contactProperties: FieldProperty[]
    addressProperties: FieldProperty[]
  }
  processQuestions?: RopaQuestion[]
  controllerQuestions?: RopaQuestion[]
}

export interface DataSubjectsQuestionnaire extends BaseRopaQuestionnaire {
  categories?: string[]
  dataSubjectProperties?: FieldProperty[]
  specialDataSubject?: boolean
  specialDataSubjectProperties?: FieldProperty[]
  specialCategories?: string[]
  specialDataSubjectsProperties?: FieldProperty[]
  lawfulBasis?: string[]
  legalBasisProperties?: FieldProperty[]
}

export type SpecialDataElement = { attributeName: string; sensitivity: string }
export interface DataElementsQuestionnaire extends BaseRopaQuestionnaire {
  attributes?: string[]
  specialElements?: SpecialDataElement[]
  lawfulBasisProperties?: FieldProperty[]
  attributeProperties?: FieldProperty[]
  specialElementProperties?: FieldProperty[]
  lawfulBases?: string[]
  associatedDatasources?: string[]
}

export type RetentionPeriod = {
  id?: string
  durationCount?: number
  durationType: string
  triggerEvent: string
  attributeSets: string[]
}
export interface DataRetentionQuestionnaire extends BaseRopaQuestionnaire {
  details?: RetentionPeriod[]
  dataRetentionProperties?: FieldProperty[]
}

export interface SafeguardsQuestionnaire extends BaseRopaQuestionnaire {
  safeguardProperties?: FieldProperty[]
  safeguards?: string[]
}

interface BaseTransferDetails {
  email?: string
  emailProperties?: FieldProperty[]
  safeguards?: string[]
  safeguardsProperties?: FieldProperty[]
}

export interface CrossBorderTransferDetails extends BaseTransferDetails {
  organisation?: string
  organisationProperties?: FieldProperty[]
  country?: string
  countryProperties?: FieldProperty[]
}
export interface ThirdPartyTransferDetails extends BaseTransferDetails {
  name?: string
  nameProperties?: FieldProperty[]
  address?: string
  addressProperties?: FieldProperty[]
}

export enum RopaTransferDetailsTypeEnum {
  CROSS_BORDER = 'crossBorder',
  THIRD_PARTY = 'thirdParty'
}
export interface TransfersQuestionnaire extends BaseRopaQuestionnaire {
  crossBorder: {
    isCrossBorder?: boolean
    crossBorderProperties?: FieldProperty[]
    details: CrossBorderTransferDetails[]
  }
  thirdParty: {
    isThirdParty?: boolean
    thirdPartyProperties?: FieldProperty[]
    details: ThirdPartyTransferDetails[]
  }
}

export interface DataFlowQuestionnaire {
  id?: string
  isAssigned?: boolean
}

export interface RopaQuestionnaireOptionalTopics {
  dataElements?: DataElementsQuestionnaire
  dataSubjects?: DataSubjectsQuestionnaire
  dataRetention?: DataRetentionQuestionnaire
  safeguards?: SafeguardsQuestionnaire
  transfers?: TransfersQuestionnaire
  customTopics?: BaseRopaQuestionnaire[]
  dataFlow?: DataFlowQuestionnaire
}

export interface RopaQuestionnaireV2 extends RopaQuestionnaireOptionalTopics {
  processDetails?: ProcessDetailsQuestionnaire
}

export type ProcessingActivityManagerDetails = {
  name: string
  email: string
  contact: string
  address: string
  roleType: ProcessingActivityRoleTypes
}

export type RopaCollaborator = {
  id: string
  email: string
  status: string
  name: string
  createdAt: string
  updateTimestamp: string
  totalSections?: number
  totalQuestions?: number
  dataElementsQuestionIds: string[]
  dataSubjectsQuestionIds: string[]
  dataRetentionQuestionIds: string[]
  safeguardsQuestionIds: string[]
  transfersQuestionIds: string[]
  dataFlowQuestionIds: string[]
  customQuestionIds: string[]
  topics?: RopaQuestionnaireOptionalTopics
  parentCollaboratorId?: string
}

export enum RopaProcessReviewStatuses {
  reviewDue = 'REVIEW_DUE',
  reviewPastDue = 'REVIEW_PAST_DUE',
  nowReviewNeeded = 'NO_REVIEW_NEEDED',
  reviewCompleted = 'REVIEW_COMPLETED'
}
export enum RopaProcessReviewDurations {
  year = 'YEAR',
  month = 'MONTH',
  week = 'WEEK'
}
export type RopaProcessSettings = {
  id?: string
  reviewDuration?: RopaProcessReviewDurations
  reviewDueDate?: string
  reviewStatus?: RopaProcessReviewStatuses
  reviewFrequency?: string
  isDueDatePast?: boolean
  isDueDateWarning?: boolean
}

export type RopaProcessV2 = {
  id: string
  ticketId?: string
  parentCollaboratorId?: string
  owner?: string
  systemTemplate?: boolean
  dpoEmail?: string
  assignedTo?: string
  templateId?: string
  usageCount?: number
  name?: string
  description?: string
  purpose?: string[]
  purposeIds?: string[]
  processGroups?: string[]
  processGroupIds?: string[]
  lawfulBasis?: string[]
  lawfulBasisIds?: string[]
  automatedProcessing?: boolean
  automatedProcessingDescription?: string
  status?: TicketStatus
  processDetails?: {
    creatorRole?: string
    processingActivityDetails?: {
      order?: number
      managerType: string
      details: ProcessingActivityManagerDetails[]
    }[]
  }
  subjectCategories?: string[]
  specialSubjectCategories?: string[]
  specialSubjectLegalBasis?: string[]
  customPurpose?: string
  customLawfulBasis?: string
  createdAt?: string
  updatedAt?: string
  systemDefined?: boolean
  reportsCount?: number
  _isNew?: boolean
  departments?: string[]
  reportGeneratedOn?: number
  questionnaire?: RopaQuestionnaireV2
  processSettings?: RopaProcessSettings
}

// Data Flow
export type RopaDataFlowDataSource = {
  id: string
  name?: string
  type?: DATA_SOURCE_TYPES
  dataFlowId?: string
  ropaProcessId?: string
  ropaTopicId?: string
  processingStage?: DataSourceProcessingStages
  sources?: RopaDataFlowDataSource[]
  targets?: RopaDataFlowDataSource[]
  isSelected?: boolean
  processId?: string
  topicId?: string
  isValid?: boolean
  isDeleted?: boolean
  isUpdated?: boolean
  isFromQuestionnaire?: boolean
}
export type RopaDataFlowParams = {
  processId: string
  topicId: string
}
export const ACTION_ROPA_DATA_FLOW = 'ropav2/dataFlow'
export const fetchRopaDataFlow = createAsyncThunk(
  ACTION_ROPA_DATA_FLOW,
  async (params: RopaDataFlowParams) => {
    const raw = await graphqlService.execute(queryRopaDataFlow(params))
    return mapQueryRopaDataFlow(raw)
  }
)

export type RopaDataFlowSaveParams = {
  processId: string
  topicId?: string
  dataSources: RopaDataFlowDataSource[]
}
export const ACTION_ROPA_DATA_FLOW_SAVE = 'ropav2/dataFlowSave'
export const saveRopaDataFlow = createAsyncThunk(
  ACTION_ROPA_DATA_FLOW_SAVE,
  async (params: RopaDataFlowSaveParams) => {
    const dsToDelete = params.dataSources.filter(
      (ds) => ds.isDeleted && (ds.dataFlowId + '')?.length > 5
    )
    const dsToUpdate = params.dataSources
      .filter((ds) => !ds.isDeleted)
      .map((ds) => {
        const updatedDs = {
          ...ds,
          dataFlowId: (ds.dataFlowId + '')?.length > 5 ? ds.dataFlowId : undefined,
          sources: ds.sources?.map((source) => ({ ...source, dataFlowId: undefined })),
          targets: ds.targets?.map((target) => ({ ...target, dataFlowId: undefined }))
        }

        return updatedDs
      })

    // delete selected data sources
    if (dsToDelete.length) {
      await graphqlService.execute(
        mutationRemoveRopaDataFlow({ ...params, dataSources: dsToDelete })
      )
    }

    // save others
    if (dsToUpdate.length) {
      await graphqlService.execute(mutationSaveRopaDataFlow({ ...params, dataSources: dsToUpdate }))
    }
  }
)

// Main State
export interface RopaReportV2 {
  id: string
  name: string
  status: string
  createdAt: string
  generatedOn: string
  processName: string
  processOwner: string
  dataSources: {
    dataSourceName: string
    dataSourceType: DATA_SOURCE_TYPES
    status: string
    isRemote: boolean
  }[]
  dpo?: string
}

export interface RopaReviewSchedule {
  reviewFrequency: number
  reviewDuration: string
}

export type SimplifiedAttributeSetAttribute = Take<
  AttributeSetAttribute,
  'id' | 'name' | 'sensitivityLabel' | 'instanceCount'
> & {
  datasources: string[]
}

type RopaV2State = {
  processes: {
    sort: SortParams
    list?: RopaProcessV2[]
    total?: number
    groups?: PiaProcessGroupCard[]
    groupsTotal?: number
  }
  templates: {
    sort: SortParams
    list?: RopaProcessV2[]
    total?: number
  }
  totalProcesses?: number
  selectedTemplate?: RopaProcessV2
  selectedTemplateOrProcess?: RopaProcessV2
  optionLists: {
    attributeSets?: AttributeSet[]
    attributes?: AttributeSetAttribute[]
    attributesInstanceCounts?: SimplifiedAttributeSetAttribute[]
    processingStages?: DataSourceProcessingStages[]
    reviewSchedules?: RopaReviewSchedule[]
  }
  selectedDatasourceDataProperties?: NameIdSummary[]
  createdDatasourceDataProperty: {
    id?: string
    type?: string
  }
  submittedTicketIds?: string[]
  collaborators?: RopaCollaborator[]
  exportProcesses?: { data: string; fileName: string }
  dataFlow: {
    dataSources?: RopaDataFlowDataSource[]
    showErrors?: boolean
    isIdUpdated?: boolean
  }
}

const initialList = {
  sort: defaultSortParams
}
export const initialState: RopaV2State = {
  processes: initialList,
  templates: initialList,
  optionLists: {},
  selectedTemplateOrProcess: undefined,
  createdDatasourceDataProperty: {},
  submittedTicketIds: undefined,
  dataFlow: {}
}

export type RopaProcessesParams = {
  [PAGE]?: number
  id?: string
  filters?: FilterParams
  isSystemDefined?: boolean
}

export const ACTION_ROPA_FETCH_PROCESSES = 'ropav2/fetch/processes'
export const fetchRopaProcesses = createAsyncThunk(
  ACTION_ROPA_FETCH_PROCESSES,
  async (params: RopaProcessesParams) => {
    const raw = await graphqlService.execute(
      queryListProcesses({ ...params, isSystemDefined: false })
    )
    return mapQueryProcesses(raw)
  }
)

export const ACTION_ROPA_FETCH_PROCESS_CARDS = 'ropa/fetch/processesCards'
export const fetchRopaProcessCards = createAsyncThunk(
  ACTION_ROPA_FETCH_PROCESS_CARDS,
  async (params: RopaProcessesParams) => {
    const raw = await graphqlService.execute(
      queryRopaProcessCards({ ...params, isSystemDefined: false })
    )
    return mapQueryRopaProcessCards(raw)
  }
)

export type RopaTotalProcessesParams = {
  owner?: string
}
export const ACTION_ROPA_FETCH_PROCESSES_TOTAL = 'ropav2/fetch/processes-total'
export const fetchTotalRopaProcesses = createAsyncThunk(
  ACTION_ROPA_FETCH_PROCESSES_TOTAL,
  async (params?: RopaTotalProcessesParams) => {
    const raw = await graphqlService.execute(queryTotalRopaProcesses(params))
    return mapQueryTotalRopaProcesses(raw)
  }
)

export const ACTION_ROPA_FETCH_TEMPLATES = 'ropav2/fetch/templates'
export const fetchRopaTemplates = createAsyncThunk(
  ACTION_ROPA_FETCH_TEMPLATES,
  async (params: RopaProcessesParams) => {
    const raw = await graphqlService.execute(
      queryListProcesses({ ...params, isSystemDefined: true })
    )
    return mapQueryProcesses(raw)
  }
)

export type RopaProcessV2Params = {
  id: string
  details?: RopaProcessV2
}
export const ACTION_ROPA_FETCH_TEMPLATE_OR_PROCESS = 'ropav2/fetch/templateOrProcessById'
export const fetchRopaTemplateOrProcessById = createAsyncThunk(
  ACTION_ROPA_FETCH_TEMPLATE_OR_PROCESS,
  async ({ id, details }: RopaProcessV2Params) => {
    const raw = await graphqlService.execute(queryProcessOrTemplateById(id))
    return mapQueryProcessOrTemplateById(raw, details)
  }
)

export const ACTION_ROPA_FETCH_COLLABORATION_DETAILS = 'ropav2/fetch/collaborationInfoById'
export const fetchRopaCollaborationInfoById = createAsyncThunk(
  ACTION_ROPA_FETCH_COLLABORATION_DETAILS,
  async (id: string) => {
    const raw = await graphqlService.execute(queryCollaboratorTicketDetailsById(id))
    return mapQueryCollaboratorTicketDetailsById(raw)
  }
)
export type AssociatedTicketsParams = { id: string; isProcess: boolean }
export const ACTION_ROPA_FETCH_ASSOCIATED_COLLABORATION_TICKETS =
  'ropav2/fetch/associatedCollaborationTickets'
export const fetchAssociatedCollaborationTickets = createAsyncThunk(
  ACTION_ROPA_FETCH_ASSOCIATED_COLLABORATION_TICKETS,
  async (params: AssociatedTicketsParams) => {
    const raw = await graphqlService.execute(queryTicketsForProcessOrTicket(params))
    return raw?.ropaCollaboration
  }
)

export const ACTION_ROPA_FETCH_TEMPLATE = 'ropav2/fetch/templateById'
export const fetchRopaTemplateById = createAsyncThunk(
  ACTION_ROPA_FETCH_TEMPLATE,
  async (id: string) => {
    const raw = await graphqlService.execute(queryProcessOrTemplateById(id))
    return mapQueryProcessOrTemplateById(raw)
  }
)

export const ACTION_CREATE_ROPA_RETENTION_PERIOD = 'ropav2/createRetentionPeriod'
export const createRetentionPeriod = createAsyncThunk(
  ACTION_CREATE_ROPA_RETENTION_PERIOD,
  async (period: RetentionPeriod): Promise<any> => {
    const response = await graphqlService.execute(queryCreateRetentionPeriod(period))
    return mapRetentionPeriod(response)
  }
)
export type DatasourceDataProperty = {
  id?: string
  value: string
  fieldType: DataSourceDataPropertyFieldTypeEnum
}
export const ACTION_CREATE_UPDATE_DATASOURCE_DATA_PROPERTY =
  'ropav2/bulkCreateDatasourceDataProperty'
export const bulkCreateOrUpdateDatasourceDataProperty = createAsyncThunk(
  ACTION_CREATE_UPDATE_DATASOURCE_DATA_PROPERTY,
  async (dataProperties: DatasourceDataProperty[]): Promise<any> => {
    const raw = await graphqlService.execute(
      queryBulkCreateOrUpdateDatasourceDataProperty(dataProperties)
    )
    return mapBulkCreateOrUpdateDatasourceDataProperty(raw)
  }
)

export const ACTION_CREATE_DATASOURCE_DATA_PROPERTY = 'ropav2/createDatasourceDataProperty'
export const createDatasourceDataProperty = createAsyncThunk(
  ACTION_CREATE_DATASOURCE_DATA_PROPERTY,
  async (property: DatasourceDataProperty): Promise<any> => {
    const raw = await graphqlService.execute(
      queryBulkCreateOrUpdateDatasourceDataProperty([property])
    )
    return mapBulkCreateOrUpdateDatasourceDataProperty(raw, property.fieldType)
  }
)

export const ACTION_ROPA_CREATE_TEMPLATE = 'ropav2/createTemplate'
export const createRopaTemplate = createAsyncThunk(
  ACTION_ROPA_CREATE_TEMPLATE,
  async (template: RopaProcessV2, { rejectWithValue }) => {
    try {
      return await graphqlService.execute(queryCreateRopaTemplate(template))
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error?.errors[0]?.message || '' })
    }
  }
)

export const ACTION_ROPA_UPDATE_TEMPLATE = 'ropav2/updateTemplate'
export const updateRopaTemplate = createAsyncThunk(
  ACTION_ROPA_UPDATE_TEMPLATE,
  async (template: RopaProcessV2): Promise<RopaProcessV2> => {
    const response = await graphqlService.execute(queryUpdateRopaTemplate(template))
    return response
  }
)

export const ACTION_ROPA_SILENT_UPDATE_PROCESS = 'ropav2/process/silent-update'
export const silentUpdateRopaProcess = createAsyncThunk(
  ACTION_ROPA_SILENT_UPDATE_PROCESS,
  async (template: RopaProcessV2): Promise<RopaProcessV2> => {
    const response = await graphqlService.execute(queryUpdateRopaTemplate(template))
    return response
  }
)

export const ACTION_ROPA_CREATE_PROCESS = 'ropav2/createProcess'
export const createRopaProcess = createAsyncThunk(
  ACTION_ROPA_CREATE_PROCESS,
  async (process: RopaProcessV2, { rejectWithValue }) => {
    try {
      const response = await graphqlService.execute(queryCreateRopaProcess(process))
      return response.createRopaProcess
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error?.errors[0]?.message || '' })
    }
  }
)
export type UpdateCollaborationTicketParams = { process: RopaProcessV2; status: TicketStatus }
export const ACTION_ROPA_UPDATE_COLLABORATION_TICKET = 'ropav2/updateCollaborationTicket'
export const updateCollaborationTicket = createAsyncThunk(
  ACTION_ROPA_UPDATE_COLLABORATION_TICKET,
  async (params: UpdateCollaborationTicketParams): Promise<RopaProcessV2> => {
    const response = await graphqlService.execute(queryUpdateCollaborationTicket(params))
    return response
  }
)

export const ACTION_ROPA_DELETE_TEMPLATE_OR_PROCESS = 'ropav2/delete/templateOrProcessById'
export const deleteRopaTemplateOrProcessById = createAsyncThunk(
  ACTION_ROPA_DELETE_TEMPLATE_OR_PROCESS,
  async (id: string) => {
    await graphqlService.execute(queryDeleteProcesOrTemplate(id))
    return { id }
  }
)

export type RopaAttributesParams = {
  dataSourceIds: string[]
  print?: boolean
  forDataElements?: boolean
}
export const ACTION_ROPA_ATTRIBUTE_SETS = 'ropav2/options/attributeSets'
export const fetchRopaAttributes = createAsyncThunk(
  ACTION_ROPA_ATTRIBUTE_SETS,
  async (params: RopaAttributesParams) => {
    const resultRaw = await graphqlService.execute(queryRopaAttributes(params))
    return mapQueryRopaAttributes(resultRaw, params)
  }
)

export const ACTION_ROPA_ATTRIBUTES_INSTANCE_COUNT = 'ropav2/options/attributesInstanceCount'
export const fetchRopaAttributesInstanceCount = createAsyncThunk(
  ACTION_ROPA_ATTRIBUTES_INSTANCE_COUNT,
  async (params: Take<RopaAttributesParams, 'dataSourceIds' | 'print' | 'forDataElements'>) => {
    const raw = await graphqlService.execute(queryRopaAttributes(params))
    return mapQueryRopaOverviewAttributesInstanceCount(raw, params)
  }
)

export type SendReminderParams = {
  id: string
  emailBody: string
  dueDate: string
}
export const ACTION_ROPA_SEND_REMINDER = 'ropav2/collaborator/sendReminder'
export const sendReminderToCollaborator = createAsyncThunk(
  ACTION_ROPA_SEND_REMINDER,
  async (params: SendReminderParams) => {
    await graphqlService.execute(mutationSendReminder(params))
  }
)

export const ACTION_ROPA_PROCESS_REVIEW_SCHEDULES = 'ropav2/options/process-review-schedules'
export const fetchRopaProcessReviewSchedules = createAsyncThunk(
  ACTION_ROPA_PROCESS_REVIEW_SCHEDULES,
  async () => {
    const raw = await graphqlService.execute(queryRopaProcessReviewSchedules())
    return mapQueryRopaProcessReviewSchedules(raw)
  }
)

export const ACTION_ROPA_REVOKE_ASSIGNMENT = 'ropav2/collaborator/revokeAssignment'
export const revokeAssignment = createAsyncThunk(
  ACTION_ROPA_REVOKE_ASSIGNMENT,
  async (params: { id: string }) => {
    await graphqlService.execute(mutationRevokeAssignment(params))
  }
)

export type RejectTicketParams = {
  id: string
  emailBody: string
}
export const ACTION_ROPA_REJECT_TICKET = 'ropav2/collaborator/rejectTicket'
export const rejectTicket = createAsyncThunk(
  ACTION_ROPA_REJECT_TICKET,
  async (params: RejectTicketParams) => {
    await graphqlService.execute(mutationRejectTicket(params))
  }
)

export type ReassignmentParams = SendReminderParams & { email: string }
export const ACTION_ROPA_REASSIGN_TICKET = 'ropav2/collaborator/reassign'
export const reassignTicket = createAsyncThunk(
  ACTION_ROPA_REASSIGN_TICKET,
  async (params: ReassignmentParams) => {
    await graphqlService.execute(mutationReassignTicket(params))
  }
)

export const ACTION_ROPA_DELETE_TICKET = 'ropav2/collaborator/delete'
export const deleteTicket = createAsyncThunk(
  ACTION_ROPA_DELETE_TICKET,
  async (params: { id: string }) => {
    await graphqlService.execute(deleteCollaborationRequest(params))
  }
)

export const ACTION_ROPA_SEND_NEW_TICKET = 'ropav2/collaborator/sendNewTicket'
export const sendNewTicketToCollaborator = createAsyncThunk(
  ACTION_ROPA_SEND_NEW_TICKET,
  async (params: SendReminderParams) => {
    await graphqlService.execute(sendRopaNewCollaborationRequest(params))
  }
)

export const ACTION_ROPA_ADD_COLLABORATOR = 'ropav2/collaborators/add'
export const ropaAddCollaborators = createAsyncThunk(
  ACTION_ROPA_ADD_COLLABORATOR,
  async (params: RopaCollaboration) => {
    await graphqlService.execute(queryAddCollaborators(params))
  }
)

export interface UpdateCollaborationParams {
  collaboratorId: string
  ropaProcessId: string
  questionnaire: RopaQuestionnaireOptionalTopics
}
export const ACTION_ROPA_UPDATE_COLLABORATOR = 'ropav2/collaborators/update'
export const ropaUpdateCollaborator = createAsyncThunk(
  ACTION_ROPA_UPDATE_COLLABORATOR,
  async (params: UpdateCollaborationParams) => {
    await graphqlService.execute(queryUpdateCollaborator(params))
  }
)

export type UpdateTicketsStatus = {
  ids: string[]
  status: TicketStatus
}
export const ACTION_ROPA_UPDATE_TICKETS_STATUS = 'ropav2/collaborator/updateTicketsStatus'
export const updateTicketsStatus = createAsyncThunk(
  ACTION_ROPA_UPDATE_TICKETS_STATUS,
  async (params: UpdateTicketsStatus) => {
    await graphqlService.execute(mutationUpdateTicketsStatus(params))
  }
)

// reminders
export type RopaProcessSettingsParams = {
  processId: string
  duration?: RopaProcessReviewDurations
  frequency?: string
}
export const ACTION_ROPA_UPDATE_PROCESS_SETTINGS = 'ropav2/updateProcessSettings'
export const updateProcessSettings = createAsyncThunk(
  ACTION_ROPA_UPDATE_PROCESS_SETTINGS,
  async (params: RopaProcessSettingsParams) => {
    await graphqlService.execute(mutationUpdateProcessSettings(params))
  }
)

export const ACTION_ROPA_PROCESSES_EXPORT = 'ropav2/exportProcesses'
export const fetchRopaProcessesCsv = createAsyncThunk(
  ACTION_ROPA_PROCESSES_EXPORT,
  async (params: DownloadListParams) => {
    const { data, headers } = await apiService.downloadFile(params)
    const disposition = headers['content-disposition']
    let fileName = ''
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
    }

    return { data, fileName }
  }
)
export const ACTION_ROPA_PROCESSING_STAGES = 'ropav2/processingStages'
export const fetchProcessingStages = createAsyncThunk(ACTION_ROPA_PROCESSING_STAGES, async () => {
  const result: DataSourceProcessingStages[] = Object.values(DataSourceProcessingStages)
  return result
})

const ropav2Slice = createSlice({
  name: 'ropav2',
  initialState,
  reducers: {
    setSelectedProcessOrTemplate: (state, { payload }: { payload: RopaProcessV2 }) => {
      state.selectedTemplateOrProcess = payload
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
        state.selectedTemplateOrProcess
      )
    },
    updateProcess: (state, { payload }) => {
      const { key, payload: data } = payload
      if (state.selectedTemplateOrProcess?.[key]) {
        state.selectedTemplateOrProcess[key] = data
        state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
          state.selectedTemplateOrProcess
        )
      }
    },
    resetSelectedProcess: (state) => {
      state.selectedTemplateOrProcess = initialState.selectedTemplateOrProcess
      state.submittedTicketIds = initialState.submittedTicketIds
    },
    resetSubmittedTicket: (state) => {
      state.submittedTicketIds = initialState.submittedTicketIds
    },
    addTopic: (state, { payload }) => {
      if (state.selectedTemplateOrProcess?.questionnaire) {
        state.selectedTemplateOrProcess.questionnaire.customTopics = [
          ...(state.selectedTemplateOrProcess.questionnaire?.customTopics?.length
            ? state.selectedTemplateOrProcess.questionnaire?.customTopics
            : []),
          payload
        ]
      }
    },
    updateTopic: (state, { payload }: { payload: { key: string; payload: any } }) => {
      if (state.selectedTemplateOrProcess?.questionnaire) {
        const { key, payload: data } = payload

        state.selectedTemplateOrProcess.questionnaire = {
          ...state.selectedTemplateOrProcess.questionnaire,
          [key]: {
            ...state.selectedTemplateOrProcess.questionnaire[key],
            ...data
          }
        }
        state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
          state.selectedTemplateOrProcess
        )
      }
    },

    updateCustomTopic: (state, { payload }: { payload: { key: string; payload: any } }) => {
      if (state.selectedTemplateOrProcess?.questionnaire) {
        const { key, payload: data } = payload
        const newCustomTopics = state.selectedTemplateOrProcess.questionnaire.customTopics?.map(
          (topic) => {
            if (topic.id === key) {
              return { ...topic, ...data }
            }
            return topic
          }
        )
        state.selectedTemplateOrProcess.questionnaire.customTopics = newCustomTopics
        state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
          state.selectedTemplateOrProcess
        )
      }
    },

    addProcessor: (state, { payload }) => {
      if (state.selectedTemplateOrProcess?.processDetails?.processingActivityDetails) {
        state.selectedTemplateOrProcess.processDetails.processingActivityDetails = [
          ...state.selectedTemplateOrProcess.processDetails.processingActivityDetails,
          payload
        ]
      }
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
        state.selectedTemplateOrProcess
      )
    },
    deleteProcessor: (state, { payload }) => {
      if (state.selectedTemplateOrProcess?.processDetails?.processingActivityDetails) {
        const newDetails = [
          ...state.selectedTemplateOrProcess.processDetails.processingActivityDetails
        ]
        newDetails.splice(payload, 1)
        state.selectedTemplateOrProcess.processDetails.processingActivityDetails = newDetails
      }
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
        state.selectedTemplateOrProcess
      )
    },

    updateManagerType: (
      state,
      { payload }: { payload: { order: number; managerType: string } }
    ) => {
      if (state.selectedTemplateOrProcess?.processDetails?.processingActivityDetails) {
        const { order, managerType } = payload
        state.selectedTemplateOrProcess.processDetails.processingActivityDetails[
          order
        ].managerType = managerType
      }
    },

    updateProcessorDetailsByOrder: (
      state,
      { payload }: { payload: { order: number; payload: ProcessingActivityManagerDetails[] } }
    ) => {
      if (state.selectedTemplateOrProcess?.processDetails?.processingActivityDetails) {
        const { order, payload: data } = payload
        state.selectedTemplateOrProcess.processDetails.processingActivityDetails[order] = state
          .selectedTemplateOrProcess.processDetails.processingActivityDetails[order] || {
          details: []
        }
        state.selectedTemplateOrProcess.processDetails.processingActivityDetails[
          order
        ].details = data
      }
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
        state.selectedTemplateOrProcess
      )
    },

    addTransferDetails: (state, { payload }) => {
      const { payload: data, type } = payload
      if (state.selectedTemplateOrProcess?.questionnaire?.transfers?.[type]) {
        state.selectedTemplateOrProcess.questionnaire.transfers[type].details = [
          ...state.selectedTemplateOrProcess.questionnaire.transfers[type].details,
          data
        ]
      }
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
        state.selectedTemplateOrProcess
      )
    },

    deleteTransferDetails: (state, { payload }) => {
      const { index, type } = payload
      if (state.selectedTemplateOrProcess?.questionnaire?.transfers?.[type]) {
        const newDetails = [
          ...state.selectedTemplateOrProcess.questionnaire.transfers[type].details
        ]
        newDetails.splice(index, 1)
        state.selectedTemplateOrProcess.questionnaire.transfers[type].details = newDetails
      }
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
        state.selectedTemplateOrProcess
      )
    },

    updateTrasferDetailsByOrder: (
      state,
      {
        payload
      }: {
        payload: {
          order: number
          type: RopaTransferDetailsTypeEnum
          payload: CrossBorderTransferDetails | ThirdPartyTransferDetails
        }
      }
    ) => {
      const { order, payload: data, type } = payload
      if (state.selectedTemplateOrProcess?.questionnaire?.transfers?.[type]) {
        state.selectedTemplateOrProcess.questionnaire.transfers[type].details[order] = {
          ...state.selectedTemplateOrProcess.questionnaire.transfers[type].details[order],
          ...data
        }
      }
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
        state.selectedTemplateOrProcess
      )
    },

    deleteCustomTopic: (state, { payload }) => {
      if (state.selectedTemplateOrProcess?.questionnaire) {
        state.selectedTemplateOrProcess.questionnaire.customTopics = state.selectedTemplateOrProcess.questionnaire.customTopics?.filter(
          (topic) => topic.id !== payload.topicId
        )
      }
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
        state.selectedTemplateOrProcess
      )
    },
    setSelectedDatasourceDataProperties: (state, { payload }) => {
      state.selectedDatasourceDataProperties = payload
    },
    resetSelectedDatasourceDataProperties: (state) => {
      state.selectedDatasourceDataProperties = []
    },
    resetListProcesses: (state) => {
      state.processes = initialState.processes
    },
    resetListTemplates: (state) => {
      state.templates = initialState.templates
    },
    resetCreatedDatasourceDataPropertyId: (state) => {
      state.createdDatasourceDataProperty = initialState.createdDatasourceDataProperty
    },
    resetSelectedTemplate: (state) => {
      state.selectedTemplate = initialState.selectedTemplate
    },
    setRopaDataFlowDataSources: (state, { payload }) => {
      state.dataFlow.dataSources = payload
    },
    updateRopaDataFlowDataSource: (state, action) => {
      const incomingDs: RopaDataFlowDataSource = {
        ...action.payload,
        isUpdated: true,
        isValid: !!action.payload.processingStage
      }

      const isDsExists = state.dataFlow.dataSources?.find(
        (ds) => ds.dataFlowId === incomingDs.dataFlowId
      )

      // update fields
      const updatedArray: RopaDataFlowDataSource[] =
        state.dataFlow.dataSources?.map((parent) => {
          const isSameDs = parent.dataFlowId === incomingDs.dataFlowId
          let isParentValid = isSameDs ? !!incomingDs.processingStage : !!parent.processingStage

          const updatedItem = {
            ...(isSameDs ? incomingDs : parent),
            isSelected: parent.isSelected,
            sources: parent.sources
              ?.filter((source) =>
                source.dataFlowId === incomingDs.dataFlowId ? !incomingDs.isDeleted : true
              )
              .map((source) => {
                const updatedSource =
                  source.dataFlowId === incomingDs.dataFlowId ? incomingDs : source
                if (
                  !updatedSource.processingStage ||
                  updatedSource.processingStage === (isSameDs ? incomingDs : parent).processingStage
                ) {
                  updatedSource.isValid = false
                  updatedSource.processingStage = undefined
                  isParentValid = false
                }
                return updatedSource
              }),
            targets: parent.targets
              ?.filter((target) =>
                target.dataFlowId === incomingDs.dataFlowId ? !incomingDs.isDeleted : true
              )
              .map((target) => {
                const updatedTarget =
                  target.dataFlowId === incomingDs.dataFlowId ? incomingDs : target
                if (
                  !updatedTarget.processingStage ||
                  updatedTarget.processingStage === (isSameDs ? incomingDs : parent).processingStage
                ) {
                  updatedTarget.isValid = false
                  updatedTarget.processingStage = undefined
                  isParentValid = false
                }
                return updatedTarget
              })
          }

          return { ...updatedItem, isValid: isParentValid }
        }) || []

      if (!isDsExists) {
        updatedArray.push(incomingDs)
      }

      // merge ds with the same stage and id
      const mergedArray: RopaDataFlowDataSource[] = []
      const dataFlowIdsToChange: Array<{ from: string; to: string }> = []
      updatedArray?.forEach((ds) => {
        const foundInMerged = mergedArray.find((merged) => {
          if (
            merged.id === ds.id &&
            !merged.isDeleted &&
            (merged.processingStage === ds.processingStage ||
              (!merged.processingStage && !ds.processingStage))
          ) {
            return true
          }
          return false
        })

        if (foundInMerged) {
          dataFlowIdsToChange.push({
            from: ds.dataFlowId || '',
            to: foundInMerged.dataFlowId || ''
          })
          // add source and target from current ds to merged based on dataflowId
          const sources =
            ds.sources?.filter(
              (source) =>
                !foundInMerged.sources?.find(({ dataFlowId }) => dataFlowId === source.dataFlowId)
            ) || []
          const targets =
            ds.targets?.filter(
              (source) =>
                !foundInMerged.targets?.find(({ dataFlowId }) => dataFlowId === source.dataFlowId)
            ) || []
          foundInMerged.sources = [...(foundInMerged.sources || []), ...sources]
          foundInMerged.targets = [...(foundInMerged.targets || []), ...targets]
        } else {
          mergedArray.push(ds)
        }
      })

      // change dataflow IDs for merged ds
      const changedIds = mergedArray.map((parent) => {
        return {
          ...parent,
          sources: parent.sources?.map((ds) => {
            const shouldChange = dataFlowIdsToChange.find(({ from }) => from === ds.dataFlowId)
            if (shouldChange) {
              ds.dataFlowId = shouldChange.to
            }

            return ds
          }),
          targets: parent.targets?.map((ds) => {
            const shouldChange = dataFlowIdsToChange.find(({ from }) => from === ds.dataFlowId)
            if (shouldChange) {
              ds.dataFlowId = shouldChange.to
            }

            return ds
          })
        }
      })

      state.dataFlow.dataSources = changedIds || []
    },
    removeRopaDataFlowDataSource: (state, action) => {
      const incomingDs: RopaDataFlowDataSource = {
        ...action.payload,
        isUpdated: true,
        isValid: !!action.payload.processingStage
      }

      const isDsExists = state.dataFlow.dataSources?.find(
        (ds) => ds.dataFlowId === incomingDs.dataFlowId
      )

      const updated = state.dataFlow.dataSources?.map((parent) => {
        const isParent = parent.dataFlowId === incomingDs.dataFlowId
        let isValid = isParent ? !!incomingDs.processingStage : parent.isValid

        const updated = {
          ...parent,
          processingStage: isParent ? incomingDs.processingStage : parent.processingStage,
          sources: parent.sources?.map((parentSource) => {
            if (parentSource.dataFlowId === incomingDs.dataFlowId) {
              isValid = incomingDs.isValid
              return incomingDs
            }
            return parentSource
          }),
          targets: parent.targets?.map((parentTarget) => {
            if (parentTarget.dataFlowId === incomingDs.dataFlowId) {
              isValid = incomingDs.isValid
              return incomingDs
            }
            return parentTarget
          })
        }

        return { ...updated, isValid }
      })

      state.dataFlow.dataSources = [...(updated || []), ...(isDsExists ? [] : [incomingDs])]
    },
    setSelectedRopaDataFlowDataSource: (state, { payload }) => {
      state.dataFlow.dataSources = state.dataFlow.dataSources?.map((ds) =>
        ds.dataFlowId === payload.dataFlowId
          ? { ...ds, isSelected: true }
          : { ...ds, isSelected: false }
      )
    },
    setShowRopaDataFlowErrors: (state, { payload }) => {
      state.dataFlow.showErrors = payload
    },
    setShowRopaDataFlowIdUpdated: (state, { payload }) => {
      state.dataFlow.isIdUpdated = payload
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchRopaTemplates.fulfilled, (state, { payload }) => {
      state.templates.list = payload.list as RopaProcessV2[]
      state.templates.total = payload.total
    })
    builder.addCase(fetchRopaProcesses.fulfilled, (state, { payload }) => {
      state.processes.list = payload.list as RopaProcessV2[]
      state.processes.total = payload.total
    })
    builder.addCase(fetchRopaProcessCards.fulfilled, (state, { payload }) => {
      state.processes.groups = payload.list
      state.processes.groupsTotal = payload.total
    })
    builder.addCase(fetchTotalRopaProcesses.fulfilled, (state, { payload }) => {
      state.totalProcesses = payload
    })
    builder.addCase(fetchRopaTemplateOrProcessById.fulfilled, (state, { payload }) => {
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(payload)
    })
    builder.addCase(fetchRopaCollaborationInfoById.fulfilled, (state, { payload }) => {
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(payload)
    })
    builder.addCase(fetchAssociatedCollaborationTickets.fulfilled, (state, { payload }) => {
      if (state.selectedTemplateOrProcess) {
        state.collaborators = getCompactCollaboratorDetails(payload)
        state.selectedTemplateOrProcess = collateCollaboratorResponses(
          state.selectedTemplateOrProcess,
          payload
        )
        state.submittedTicketIds = payload?.edges
          ?.filter(({ node }) => node.status === TicketStatus.Submitted)
          ?.map(({ node }) => node?.id)
        state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
          state.selectedTemplateOrProcess
        )
      }
    })
    builder.addCase(fetchRopaTemplateById.fulfilled, (state, { payload }) => {
      state.selectedTemplate = payload
    })
    builder.addCase(createRopaProcess.fulfilled, (state, { payload }) => {
      if (state.selectedTemplateOrProcess) {
        state.selectedTemplateOrProcess = {
          ...state.selectedTemplateOrProcess,
          id: payload.ropaProcessId
        }
      }
    })

    builder.addCase(fetchRopaProcessReviewSchedules.fulfilled, (state, { payload }) => {
      state.optionLists.reviewSchedules = payload
    })
    builder.addCase(fetchRopaAttributes.fulfilled, (state, { payload }) => {
      state.optionLists.attributes = payload.attributes
    })
    builder.addCase(fetchRopaAttributesInstanceCount.fulfilled, (state, { payload }) => {
      state.optionLists.attributesInstanceCounts = payload
    })
    builder.addCase(createRetentionPeriod.fulfilled, (state, { payload }) => {
      if (state.selectedTemplateOrProcess?.questionnaire?.dataRetention?.details?.length) {
        state.selectedTemplateOrProcess.questionnaire.dataRetention.details = [
          ...state.selectedTemplateOrProcess.questionnaire.dataRetention.details,
          payload
        ]
      } else if (state.selectedTemplateOrProcess?.questionnaire?.dataRetention) {
        state.selectedTemplateOrProcess.questionnaire.dataRetention.details = [payload]
      }
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
        state.selectedTemplateOrProcess
      )
    })
    builder.addCase(bulkCreateOrUpdateDatasourceDataProperty.fulfilled, (state, { payload }) => {
      if (state?.selectedDatasourceDataProperties) {
        const uniqValues = uniq(state.selectedDatasourceDataProperties.map((prop) => prop.name))
        state.selectedDatasourceDataProperties = uniqValues.map((val, index) => ({
          id: payload?.ids?.[index],
          name: val
        }))
      }
    })
    builder.addCase(createDatasourceDataProperty.fulfilled, (state, { payload }) => {
      state.createdDatasourceDataProperty = { id: payload.ids?.[0], type: payload.fieldType }
    })
    builder.addCase(updateTicketsStatus.fulfilled, (state) => {
      state.submittedTicketIds = initialState.submittedTicketIds
    })
    builder.addCase(fetchRopaProcessesCsv.fulfilled, (state, { payload }) => {
      state.exportProcesses = payload
    })
    builder.addCase(fetchProcessingStages.fulfilled, (state, { payload }) => {
      state.optionLists.processingStages = payload
    })
    builder.addCase(fetchRopaDataFlow.fulfilled, (state, { payload }) => {
      const map = payload.map((parent) => ({
        dataFlowId: parent.dataFlowId,
        id: parent.id,
        processingStage: parent.processingStage
      }))

      const dtfWithIds = payload.map((parent) => {
        const sources = parent.sources?.map((ds) => {
          const foundMap = map.find(
            (item) => item.id === ds.id && item.processingStage === ds.processingStage
          )

          return foundMap ? { ...ds, dataFlowId: foundMap.dataFlowId || '' } : ds
        })
        const targets = parent.targets?.map((ds) => {
          const foundMap = map.find(
            (item) => item.id === ds.id && item.processingStage === ds.processingStage
          )

          return foundMap ? { ...ds, dataFlowId: foundMap.dataFlowId || '' } : ds
        })

        return { ...parent, sources, targets }
      })

      state.dataFlow.dataSources = dtfWithIds
    })
    builder.addCase(ropaAddCollaborators.fulfilled, (state) => {
      state.dataFlow.isIdUpdated = true
    })
    builder.addCase(saveRopaDataFlow.fulfilled, (state) => {
      if (state.dataFlow?.dataSources?.length) {
        state.dataFlow.dataSources = state.dataFlow.dataSources.map((parent) => {
          return {
            ...parent,
            isUpdated: false,
            targets: parent.targets?.map((ds) => ({ ...ds, isUpdated: false })),
            sources: parent.sources?.map((ds) => ({ ...ds, isUpdated: false }))
          }
        })
      }
    })
  }
})

export const {
  resetListProcesses,
  resetListTemplates,
  setSelectedProcessOrTemplate,
  updateProcess,
  resetSelectedProcess,
  addTopic,
  updateTopic,
  updateCustomTopic,
  addProcessor,
  deleteProcessor,
  updateManagerType,
  updateProcessorDetailsByOrder,
  addTransferDetails,
  deleteTransferDetails,
  updateTrasferDetailsByOrder,
  deleteCustomTopic,
  setSelectedDatasourceDataProperties,
  resetSelectedDatasourceDataProperties,
  resetCreatedDatasourceDataPropertyId,
  resetSelectedTemplate,
  resetSubmittedTicket,
  setRopaDataFlowDataSources,
  updateRopaDataFlowDataSource,
  setSelectedRopaDataFlowDataSource,
  setShowRopaDataFlowErrors,
  setShowRopaDataFlowIdUpdated
} = ropav2Slice.actions

export default ropav2Slice.reducer
