import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import './dataTable_V2.less'
import RegularColumn from './components/regularColumn'
import { getMaskedString } from '../../utils/stringUtil'
import { ColDef, GridOptions } from 'ag-grid-community'
import { Checkbox, TableBodyProps } from 'semantic-ui-react'
import { IntlShape } from 'react-intl'
import React, { useMemo, useState } from 'react'
import {
  Table,
  TableColumn,
  TableHeaderProps,
  TableRowSelectionType
} from '@lightbeamai/design-system'

export interface GetColumnBaseParams {
  intl?: IntlShape
}
export interface IDataExpandable {
  isExpanded: boolean
  cellRenderer: () => React.ReactElement | string | number | boolean
}
export type ExpandedableListItem<T> = T & { expandedMasterId?: string }
export interface TableParams extends Omit<GridOptions, 'onSelectionChanged'> {
  rowHeight?: number
  hasActionColumn?: boolean
  tableFlex?: number
  className?: string
  rowSelection?: 'multiple' | 'single'
  expandedRowHeight?: number
  tableColumnsResizable?: boolean
  isPrint?: boolean
  dataKey?: string
  selectedRows?: string[]
  allowTotalRecordsSelection?: boolean
  multiRowSelectionOptions?: any[]
  onGridUpdate?: (event) => void
  setApi?: (event) => void
  setGridRef?: (ref) => void
  onSelectionChanged?: (ids: React.Key[], eventType: TableRowSelectionType | undefined) => void
  disableRowSelection?: (data: TableBodyProps) => boolean
}
export interface ITableColumn<T = any> extends ColDef<T> {
  title?: string
  dataKey?: string
  masking?: boolean
  checkboxColumn?: boolean
  maskingToggleDisabled?: boolean
  center?: boolean
  frozen?: boolean
}
interface IProps {
  columns: ITableColumn[]
  data: TableBodyProps[]
  tableParams?: TableParams
  header?: TableHeaderProps
  customHeader?: React.ReactNode
  customPagination?: React.ReactNode
  totalCount?: number
  showLoader?: boolean
}

export const AG_CLASS_DISABLED_CHECKBOX_ROW = 'ag-row-cb-disabled'
const DataTableV2 = ({
  columns,
  data,
  tableParams,
  header,
  customHeader,
  customPagination,
  totalCount,
  showLoader = false
}: IProps) => {
  const {
    onSelectionChanged,
    disableRowSelection,
    tableColumnsResizable = true,
    fullWidthCellRenderer,
    rowSelection = 'multiple',
    dataKey,
    className,
    selectedRows,
    allowTotalRecordsSelection,
    multiRowSelectionOptions = []
  } = tableParams || {}

  const maskingDefaultConfig = columns
    .filter(({ masking }) => masking)
    .reduce((acc, { dataKey = '' }) => {
      acc[dataKey] = true
      return acc
    }, {})

  const [maskingToggle, setMaskingToggle] = useState(maskingDefaultConfig)

  const MaskEnabledHeaderComponent = ({ displayName = '', columnId }) => {
    return (
      <div className="align-center">
        {displayName}
        <Checkbox
          toggle
          title={displayName}
          checked={maskingToggle[columnId]}
          onChange={(e) => {
            e.stopPropagation()
            setMaskingToggle({
              ...maskingToggle,
              [columnId]: maskingToggle ? !maskingToggle[columnId] : true
            })
          }}
          label=""
          className="simple fitted xs-ml-8"
        />
      </div>
    )
  }

  const maskedValueCellRenderer = ({ columnId, data }) => {
    return (
      <RegularColumn
        content={
          maskingToggle && maskingToggle[columnId]
            ? getMaskedString(data[columnId])
            : data[columnId]
        }
      />
    )
  }

  const columnsDef: TableColumn[] = useMemo(() => {
    return columns.map((column) => {
      return {
        field: column.dataKey || '',
        header:
          column.masking && !column.maskingToggleDisabled
            ? MaskEnabledHeaderComponent({ displayName: column.title, columnId: column.dataKey })
            : column.title,
        style: {
          width: `${column.minWidth}px`,
          minWidth: `${column.minWidth}px`
        },

        align: column.center ? 'center' : 'left',
        body: (data) => {
          if (column.cellRenderer) {
            return column.cellRenderer({ data })
          } else if (column.masking && !column.maskingToggleDisabled) {
            return maskedValueCellRenderer({ columnId: column.dataKey, data })
          }
          return undefined
        },
        bodyClassName: Array.isArray(column.cellClass)
          ? column.cellClass?.join(' ')
          : (column.cellClass as string),
        frozen: column.frozen
      }
    })
  }, [columns, maskingToggle])

  return (
    <div
      style={{ width: '100%', height: 'auto' }}
      data-test-id="data-table"
      className="data-table-v2"
    >
      <Table
        columns={columnsDef}
        data={data}
        dataKey={dataKey ? dataKey : 'id'}
        rowSelection={onSelectionChanged && rowSelection == 'multiple' ? true : false}
        pagination={false}
        onRowSelect={(rows, eventType) => onSelectionChanged && onSelectionChanged(rows, eventType)}
        resizableColumns={tableColumnsResizable}
        header={header}
        rowExpansionTemplate={fullWidthCellRenderer}
        columnResizeMode="expand"
        customHeader={customHeader}
        customPagination={customPagination}
        className={`${className} ag-theme-alpine`}
        disableRowSelection={disableRowSelection}
        totalRecords={totalCount}
        allowTotalRecordsSelection={allowTotalRecordsSelection}
        multiRowSelectionOptions={multiRowSelectionOptions}
        selectedRows={selectedRows}
        loading={showLoader}
      />
    </div>
  )
}

export default DataTableV2
