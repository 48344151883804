import {
  BaseRopaQuestionnaire,
  CrossBorderTransferDetails,
  DataElementsQuestionnaire,
  DataRetentionQuestionnaire,
  DataSubjectsQuestionnaire,
  DatasourceDataProperty,
  FieldProperty,
  ProcessDetailsQuestionnaire,
  RetentionPeriod,
  ReassignmentParams,
  RopaProcessV2,
  RopaProcessesParams,
  RopaQuestion,
  SafeguardsQuestionnaire,
  SendReminderParams,
  SpecialDataElement,
  ThirdPartyTransferDetails,
  TransfersQuestionnaire,
  UpdateCollaborationTicketParams,
  TicketStatus,
  AssociatedTicketsParams,
  UpdateCollaborationParams,
  RejectTicketParams,
  RopaProcessSettingsParams,
  RopaDataFlowDataSource,
  RopaDataFlowParams,
  DataFlowQuestionnaire,
  RopaDataFlowSaveParams,
  RopaProcessSettings,
  RopaReviewSchedule,
  RopaProcessReviewStatuses,
  RopaTotalProcessesParams
} from './ropaSliceV2'
import { getTotalProcessDetailsQuestions, isObjectId, mapTopicsToQuestionnaire } from './ropaUtil'
import { RopaCollaboration } from './ropaSlice'
import {
  LIMIT_DEFAULT,
  PrivacyFeatureType,
  ProcessFilterKeys,
  ProcessSortValues,
  RopaQuestionnaireTopicNames
} from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { escapeNewlineandTab } from '../../utils/stringUtil'
import { PiaProcessGroupCard } from '../pia/piaSlice'
import { gql } from 'graphql-request'

const stringify = (params?) => {
  if (!params) return ''
  if (params?.length === 0) return '[]'
  return JSON.stringify(params)
}

export const queryListProcesses = ({ id, page, filters, isSystemDefined }: RopaProcessesParams) => {
  const filtersParameterized = parameterizeArrayofObjects(filters?.filter || [])
  let filterParams = ''
  if (filtersParameterized) {
    filterParams = `, filter:${filtersParameterized}`
  }
  const cursor = getAfterCursor(page || 1, LIMIT_DEFAULT)
  const queryFilters = id
    ? `id: "${id}"`
    : `booleanFilter: [{
    key: IS_SYSTEM_DEFINED,
    value: ${isSystemDefined}
  }]`
  const pageParams = page === undefined ? '' : `first: ${LIMIT_DEFAULT}, after: "${cursor}",`

  let sortQuery = `, sortField: ${ProcessSortValues.updatedAt}`
  if (filters?.sortBy) {
    sortQuery = `sortField: ${filters?.sortBy}`
    if (filters?.sortBy === ProcessSortValues.name) {
      sortQuery += ', sortByAsc: true'
    }
  }

  return gql`{
    ropaProcess ( ${pageParams} ${queryFilters} ${sortQuery} ${filterParams}) {
      count
      edges {
        node {
          id
          name
          updatedAt
          createdAt
          createdBy
          usageCount
          systemTemplate
          processGroups {
            edges {
              node {
                value
              }
            }
          }
          ${processSettingsFragment}
        }
      }
    }
  }`
}
export const mapQueryProcesses = (raw: any): { list: RopaProcessV2[]; total: number } => {
  try {
    const list: RopaProcessV2[] =
      raw.ropaProcess?.edges?.map(({ node: process }) => {
        return {
          id: process.id,
          name: process.name,
          description: process.description || '',
          owner: process.createdBy || '',
          updatedAt: process.updatedAt || '',
          createdAt: process.createdAt || '',
          usageCount: process.usageCount || 0,
          systemTemplate: process.systemTemplate || false,
          processGroups:
            process.processGroups.edges.map((processGroup) => processGroup.node.value || '') || [],
          systemDefined: process.systemDefined || false,
          processSettings: mapProcessSettings(
            process?.processSettings?.edges[process?.processSettings?.edges?.length - 1]?.node
          )
        }
      }) || []
    return { list, total: raw.ropaProcess.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRopaProcessCards = ({ id, filters, isSystemDefined }: RopaProcessesParams) => {
  const filtersParameterized = parameterizeArrayofObjects(filters?.filter || [])
  let filterParams = ''
  if (filtersParameterized) {
    filterParams = `, filter:${filtersParameterized}`
  }
  const queryFilters = id
    ? `id: "${id}"`
    : `booleanFilter: [{
    key: IS_SYSTEM_DEFINED,
    value: ${isSystemDefined}
  }]`

  const sortQuery = `, sortField: ${ProcessSortValues.name}, sortByAsc: true`

  return gql`{
    processGroup (${queryFilters} ${filterParams} ${sortQuery}) {
      count
      edges {
        node {
          count
          inReviewCount
          processGroupId
          processGroupName
        }
      }
    }
  }`
}
export const mapQueryRopaProcessCards = (
  raw: any
): { list: PiaProcessGroupCard[]; total: number } => {
  try {
    const list: PiaProcessGroupCard[] =
      raw.processGroup?.edges?.map(({ node: group }) => {
        return {
          id: group?.processGroupId,
          name: group?.processGroupName,
          processesTotal: group?.count,
          processesInReview: group?.inReviewCount
        }
      }) || []
    return { list, total: raw.processGroup.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryTotalRopaProcesses = (params?: RopaTotalProcessesParams) => {
  const filterQuery = params?.owner
    ? `, filter: [{ key: ${ProcessFilterKeys.ownerOrReviewer}, values: ["${params?.owner}"] }]`
    : ''

  return gql`
    {
      ropaProcess(booleanFilter: [{ key: IS_SYSTEM_DEFINED, value: false }] ${filterQuery}) {
        count
      }
    }
  `
}

export const mapQueryTotalRopaProcesses = (raw: any): number => {
  try {
    return raw.ropaProcess.count || 0
  } catch (error) {
    console.error(error)
    throw error
  }
}

const getPropertiesQuery = (properties?: FieldProperty[]) => {
  if (!properties) return ''
  return properties
    .map(
      (prop) => `
    {
      key: "${prop.key}"
      value: ${stringify(prop.value)}
    }
  `
    )
    .join()
}

const getQuestionsQuery = (questions?: RopaQuestion[]) => {
  if (!questions?.length) return ''
  return questions
    ?.map(
      (question) => `
   {
      question: "${question.question}"
      options: ${stringify(question.options || [])}
      isMandatory: ${question.isMandatory}
      responseType: ${question.responseType}
      questionResponse: ${stringify(question.questionResponse || [])}
      ${isObjectId(question?.questionId) ? `questionId: "${question.questionId}"` : ''}
   }
  `
    )
    .join()
}

const getCustomTopicQuery = (topic: BaseRopaQuestionnaire) => {
  return `
    {
      ${isObjectId(topic?.id) ? `id: "${topic.id}"` : ''}
      name: "${topic.name}"
      optional: ${!!topic?.optional}
      description: ${topic.description ? stringify(topic.description) : '""'}
      ${
        topic.questionIds
          ? ` questionIds: ${stringify(topic.questionIds)}`
          : ` questions: [${getQuestionsQuery(topic.questions)}]`
      }
    }
  `
}

const getProcessDetailsPropertiesFragment = (processDetails?: ProcessDetailsQuestionnaire) => {
  if (!processDetails) return ''
  return `
    nameProperties: [${getPropertiesQuery(processDetails.nameProperties)}]
    descriptionProperties: [${getPropertiesQuery(processDetails.descriptionProperties)}]
  processGroupsProperties: [${getPropertiesQuery(processDetails.processGroupsProperties)}]
    automatedProcessingProperties: [${getPropertiesQuery(
      processDetails.automatedProcessingProperties
    )}]
    automatedProcessingDescriptionProperties: [${getPropertiesQuery(
      processDetails.automatedProcessingDescriptionProperties
    )}]
    purposeProperties: [${getPropertiesQuery(processDetails.purposeProperties)}]
    lawfulBasisProperties: [${getPropertiesQuery(processDetails.lawfulBasisProperties)}]
    processDetailsProperties: [${getPropertiesQuery(processDetails.processDetailsProperties)}]
  `
}

const getSpeicalDataElementsFragment = (specialDataElements?: SpecialDataElement[]) => {
  if (!specialDataElements) return ''
  return specialDataElements
    .map(
      (el) => `
    {
      attributeName: "${el.attributeName}"
      sensitivity: "${el.sensitivity}"
    }
  `
    )
    .join()
}

const getDataElementsPropertiesFragment = (
  dataElements?: DataElementsQuestionnaire,
  lawfulBasisProperties?: FieldProperty[]
) => {
  if (!dataElements) return ''
  return `dataElements: {
    ${dataElements.id ? `id: "${dataElements.id}"` : ''}
    name: "${dataElements.name}"
    description: ${stringify(dataElements.description || '')}
    optional: ${!!dataElements.optional}
    questions: [${getQuestionsQuery(dataElements?.questions)}]
    attributeIds: ${stringify(dataElements?.attributes || [])}
    specialElements: [${getSpeicalDataElementsFragment(dataElements?.specialElements)}]
    associatedDatasourceIds: ${stringify(dataElements?.associatedDatasources || [])}
    lawfulBasisIds: ${stringify(dataElements.lawfulBases || [])}
    attributeProperties: [${getPropertiesQuery(dataElements.attributeProperties)}]
    attributeProperties: [${getPropertiesQuery(dataElements.attributeProperties)}]
    lawfulBasisProperties: [${getPropertiesQuery(lawfulBasisProperties)}]
  }`
}
const getDataElementsPropertiesCollaboratorFragment = (
  dataElements?: DataElementsQuestionnaire
) => {
  if (!dataElements) return ''
  return `dataElements: {
    id: "${dataElements.id}"
    name: "${dataElements.name}"
    description: ${stringify(dataElements.description || '')}
    optional: ${!!dataElements.optional}
    questions: [${getQuestionsQuery(dataElements?.questions)}]
    attributeIds: ${stringify(dataElements?.attributes || [])}
    attributeProperties: [${getPropertiesQuery(dataElements.attributeProperties)}]
    specialElements: [${getSpeicalDataElementsFragment(dataElements?.specialElements)}]
    specialElementProperties: [${getPropertiesQuery(dataElements.specialElementProperties)}]
    associatedDatasourceIds: ${stringify(dataElements?.associatedDatasources || [])}
    lawfulBasisIds: ${stringify(dataElements.lawfulBases || [])}
    lawfulBasisProperties: [${getPropertiesQuery(dataElements.lawfulBasisProperties)}]
    questionIds: ${stringify(dataElements?.questionIds || [])}
  }`
}
const getDataSubjectsPropertiesFragment = (dataSubjects?: DataSubjectsQuestionnaire) => {
  if (!dataSubjects) return ''
  return `dataSubjects: {
    ${dataSubjects.id ? `id: "${dataSubjects.id}"` : ''}
    name: "${dataSubjects.name}"
    description: ${stringify(dataSubjects.description || '')}
    optional: ${!!dataSubjects.optional}
    questions: [${getQuestionsQuery(dataSubjects.questions)}]
    dataSubjectProperties: [${getPropertiesQuery(dataSubjects.dataSubjectProperties)}]
    specialDataSubjectProperties: [${getPropertiesQuery(dataSubjects.specialDataSubjectProperties)}]
    specialDataSubjectsProperties: [${getPropertiesQuery(
      dataSubjects.specialDataSubjectsProperties
    )}]
    legalBasisProperties: [${getPropertiesQuery(dataSubjects.legalBasisProperties)}]
    dataSubjectIds: ${stringify(dataSubjects.categories || [])}
    specialDataSubjectIds: ${stringify(dataSubjects.specialCategories || [])}
    specialDataSubject: ${
      dataSubjects.specialDataSubject !== undefined ? dataSubjects.specialDataSubject : false
    }
    legalBasisIds: ${stringify(dataSubjects.lawfulBasis || [])}
  }`
}

const getDataSubjectsPropertiesCollaboratorFragment = (
  dataSubjects?: DataSubjectsQuestionnaire
) => {
  if (!dataSubjects) return ''
  return `dataSubjects: {
    id: "${dataSubjects.id}"
    name: "${dataSubjects.name}"
    description: ${stringify(dataSubjects.description || '')}
    optional: ${!!dataSubjects.optional}
    questions: [${getQuestionsQuery(dataSubjects.questions)}]
    specialDataSubjectIds: []
    legalBasisIds: []
    dataSubjectIds: []
    dataSubjectProperties: [${getPropertiesQuery(dataSubjects.dataSubjectProperties)}]
    specialDataSubjectProperties: [${getPropertiesQuery(dataSubjects.specialDataSubjectProperties)}]
    specialDataSubjectsProperties: [${getPropertiesQuery(
      dataSubjects.specialDataSubjectsProperties
    )}]
    legalBasisProperties: [${getPropertiesQuery(dataSubjects.legalBasisProperties)}]
    dataSubjectIds: ${stringify(dataSubjects.categories || [])}
    specialDataSubjectIds: ${stringify(dataSubjects.specialCategories || [])}
    specialDataSubject: ${
      dataSubjects.specialDataSubject !== undefined ? dataSubjects.specialDataSubject : false
    }
    questionIds: ${stringify(dataSubjects?.questionIds || [])}
  }`
}

const getDataRetentionPropertiesFragment = (dataRetention?: DataRetentionQuestionnaire) => {
  if (!dataRetention) return ''
  return `dataRetention: {
    ${dataRetention.id ? `id: "${dataRetention.id}"` : ''}
    name: "${dataRetention.name}"
    description: ${stringify(dataRetention.description || '')}
    optional: ${!!dataRetention.optional}
    questions: [${getQuestionsQuery(dataRetention.questions)}]
    dataRetentionIds: ${stringify(dataRetention.details?.map((detail) => detail.id) || [])}
    dataRetentionProperties: [${getPropertiesQuery(dataRetention.dataRetentionProperties)}]
  }`
}

const getDataRetentionPropertiesCollaboratorFragment = (
  dataRetention?: DataRetentionQuestionnaire
) => {
  if (!dataRetention) return ''
  return `dataRetention: {
    id: "${dataRetention.id}"
    name: "${dataRetention.name}"
    description: ${stringify(dataRetention.description || '')}
    optional: ${!!dataRetention.optional}
    questions: [${getQuestionsQuery(dataRetention.questions)}]
    dataRetentionIds: []
    dataRetentionProperties: [${getPropertiesQuery(dataRetention.dataRetentionProperties)}]
    questionIds: ${stringify(dataRetention?.questionIds || [])}
  }`
}

const getSafeguardsPropertiesFragment = (safeguards?: SafeguardsQuestionnaire) => {
  if (!safeguards) return ''
  return `safeguards: {
    ${safeguards.id ? `id: "${safeguards.id}"` : ''}
    name: "${safeguards.name}"
    description: ${stringify(safeguards.description || '')}
    optional: ${!!safeguards.optional}
    questions: [${getQuestionsQuery(safeguards.questions)}]
    safeguardProperties: [${getPropertiesQuery(safeguards.safeguardProperties)}]
    safeguardIds: ${stringify(safeguards.safeguards || [])}
  }`
}

const getSafeguardsPropertiesCollaboratorFragment = (safeguards?: SafeguardsQuestionnaire) => {
  if (!safeguards) return ''
  return `safeguards: {
    id: "${safeguards.id}"
    name: "${safeguards.name}"
    description: ${stringify(safeguards.description || '')}
    optional: ${!!safeguards.optional}
    questions: [${getQuestionsQuery(safeguards.questions)}]
    safeguardIds: []
    safeguardProperties: [${getPropertiesQuery(safeguards.safeguardProperties)}]
    questionIds: ${stringify(safeguards?.questionIds || [])}
  }`
}

const getThirdPartyTransferDetailsFragment = (transfers: ThirdPartyTransferDetails[]) => {
  if (!transfers) return ``
  return transfers
    .map(
      (transfer) => `
    {
      name: "${transfer.name}"
      address: "${transfer.address}"
      email: "${transfer.email}"
      safeguards: ${stringify(transfer.safeguards || [])}
      nameProperties: [${getPropertiesQuery(transfer?.nameProperties)}]
      addressProperties: [${getPropertiesQuery(transfer?.addressProperties)}]
      emailProperties: [${getPropertiesQuery(transfer?.emailProperties)}]
      safeguardsProperties: [${getPropertiesQuery(transfer?.safeguardsProperties)}]
    }
  `
    )
    .join()
}

const getCrossBorderTransferDetailsFragment = (transfers: CrossBorderTransferDetails[]) => {
  if (!transfers) return ``
  return transfers
    .map(
      (transfer) => `
    {
      organisation: "${transfer.organisation}"
      country: "${transfer.country}"
      email: "${transfer.email}"
      safeguards: ${stringify(transfer.safeguards || [])}
      organisationProperties: [${getPropertiesQuery(transfer?.organisationProperties)}]
      countryProperties: [${getPropertiesQuery(transfer?.countryProperties)}]
      emailProperties: [${getPropertiesQuery(transfer?.emailProperties)}]
      safeguardsProperties: [${getPropertiesQuery(transfer?.safeguardsProperties)}]
    }
  `
    )
    .join()
}

const getTransfersPropertiesFragment = (transfers?: TransfersQuestionnaire) => {
  if (!transfers) return ''
  return `transfers: {
    ${transfers.id ? `id: "${transfers.id}"` : ''}
    name: "${transfers.name}"
    description: ${stringify(transfers.description || '')}
    optional: ${!!transfers.optional}
    questions: [${getQuestionsQuery(transfers.questions)}]
    thirdParty: {
      thirdPartyProperties: [${getPropertiesQuery(transfers.thirdParty?.thirdPartyProperties)}]
      isThirdParty: ${
        transfers.thirdParty?.isThirdParty !== undefined
          ? transfers.thirdParty?.isThirdParty
          : false
      }
      details:  [${getThirdPartyTransferDetailsFragment(transfers.thirdParty.details)}]
    }
    crossBorder: {
      crossBorderProperties: [${getPropertiesQuery(transfers.crossBorder?.crossBorderProperties)}]
      isCrossBorder: ${
        transfers.crossBorder?.isCrossBorder !== undefined
          ? transfers.crossBorder?.isCrossBorder
          : false
      }
      details:  [${getCrossBorderTransferDetailsFragment(transfers.crossBorder.details)}]
    }
  }`
}

const getTransfersPropertiesCollaboratorFragment = (transfers?: TransfersQuestionnaire) => {
  if (!transfers) return ''
  return `transfers: {
    id: "${transfers.id}"
    name: "${transfers.name}"
    description: ${stringify(transfers.description || '')}
    optional: ${!!transfers.optional}
    questions: [${getQuestionsQuery(transfers.questions)}]
    thirdParty: {
      thirdPartyProperties: [${getPropertiesQuery(transfers.thirdParty?.thirdPartyProperties)}]
      isThirdParty: ${
        transfers.thirdParty?.isThirdParty !== undefined
          ? transfers.thirdParty?.isThirdParty
          : false
      }
      details:  [${getThirdPartyTransferDetailsFragment(transfers.thirdParty.details)}]
    }
    crossBorder: {
      crossBorderProperties: [${getPropertiesQuery(transfers.crossBorder?.crossBorderProperties)}]
      isCrossBorder: ${
        transfers.crossBorder?.isCrossBorder !== undefined
          ? transfers.crossBorder?.isCrossBorder
          : false
      }
      details:  [${getCrossBorderTransferDetailsFragment(transfers.crossBorder.details)}]
    }
    questionIds: ${stringify(transfers?.questionIds || [])}
  }`
}

const getDataFlowFragment = (dataFlow?: DataFlowQuestionnaire) => {
  if (!dataFlow) return ''
  return `dataFlow: {
    ${dataFlow?.id ? `id: "${dataFlow.id}"` : ''}
    name: "${RopaQuestionnaireTopicNames.dataFlow}"
    isAssigned: ${!!dataFlow?.isAssigned}
  }`
}

const getProcessActivityDetailsFragment = (process: RopaProcessV2) => {
  if (!process) return ''
  return process.processDetails?.processingActivityDetails
    ?.map(
      (details) => `
   {
      managerType: ${details.managerType}
      details: [${details.details
        .map(
          (detail) => `{
        name: "${detail.name}",
        email: "${detail.email}"
        contact: "${detail.contact}"
        address: "${detail.address}"
        roleType: ${detail.roleType}
        nameProperties: [${getPropertiesQuery(
          process.questionnaire?.processDetails?.processDetailsQuestionProperties.nameProperties
        )}]
        emailProperties: [${getPropertiesQuery(
          process.questionnaire?.processDetails?.processDetailsQuestionProperties.emailProperties
        )}]
        contactProperties: [${getPropertiesQuery(
          process.questionnaire?.processDetails?.processDetailsQuestionProperties.contactProperties
        )}]
        addressProperties: [${getPropertiesQuery(
          process.questionnaire?.processDetails?.processDetailsQuestionProperties.addressProperties
        )}]
      }`
        )
        .join()}]
   }
  `
    )
    .join()
}

const getCustomTopicFragment = (customTopics?: BaseRopaQuestionnaire[]) => {
  if (!customTopics || customTopics.length === 0) return ''
  const topicsQuery = customTopics.map((topic) => getCustomTopicQuery(topic)).join()
  return `
    topics: [${topicsQuery}]
  `
}

export const getQuestionnaireFragment = (params: {
  process: RopaProcessV2
  isTemplate?: boolean
  isCollaborator?: boolean
  dataFlow?: DataFlowQuestionnaire
}) => {
  const { process, isTemplate = false, dataFlow } = params
  // create lawful basis properties from process details while creating template
  const dataElementsPropertiesQuery = getDataElementsPropertiesFragment(
    process.questionnaire?.dataElements,
    isTemplate
      ? process.questionnaire?.processDetails?.lawfulBasisProperties
      : process.questionnaire?.dataElements?.lawfulBasisProperties
  )
  const dataSubjectsPropertiesQuery = getDataSubjectsPropertiesFragment(
    process.questionnaire?.dataSubjects
  )
  const dataRetentionPropertiesQuery = getDataRetentionPropertiesFragment(
    process.questionnaire?.dataRetention
  )
  const safeguardsPropertiesQuery = getSafeguardsPropertiesFragment(
    process.questionnaire?.safeguards
  )
  const transfersPropertiesQuery = getTransfersPropertiesFragment(process.questionnaire?.transfers)

  const dataFlowFragment = getDataFlowFragment(dataFlow || process.questionnaire?.dataFlow)
  const customTopicsQuery = getCustomTopicFragment(process.questionnaire?.customTopics)
  return gql`
    ${dataElementsPropertiesQuery}
    ${dataSubjectsPropertiesQuery}
    ${dataRetentionPropertiesQuery}
    ${safeguardsPropertiesQuery}
    ${transfersPropertiesQuery}
    ${customTopicsQuery}
    ${dataFlowFragment}
  `
}

const getCreateOrUpdateProcessOrTemplateFragment = (
  process: RopaProcessV2,
  dataFlow?: DataFlowQuestionnaire
) => {
  const processDetailsPropertiesQuery = getProcessDetailsPropertiesFragment(
    process.questionnaire?.processDetails
  )

  return `
      systemDefined: ${process.systemDefined}
      name: "${process.name}"
      owner: "${process.owner}"
      description: "${escapeNewlineandTab(process.description || '')}"
      dpoEmail: "${process.dpoEmail}"
      automatedProcessing: ${process.automatedProcessing}
      automatedProcessingDescription: "${escapeNewlineandTab(
        process?.automatedProcessingDescription || ''
      )}"
      purposeIds: ${stringify(process.purpose)}
      lawfulBasisIds: ${stringify(process.lawfulBasis || [])}
      processGroupIds: ${stringify(process.processGroups || [])}
      ${processDetailsPropertiesQuery}
      controllerQuestions: [${[
        getQuestionsQuery(process.questionnaire?.processDetails?.controllerQuestions)
      ]}]
      processQuestions: [${[
        getQuestionsQuery(process.questionnaire?.processDetails?.processQuestions)
      ]}]
      processDetails: {
        processingActivityDetails: [${getProcessActivityDetailsFragment(process)}]
      }
      questionnaire: {
        ${getQuestionnaireFragment({ process, isTemplate: !!process.systemDefined, dataFlow })}
      }

  `
}

export const queryCreateRopaTemplate = (process: RopaProcessV2) => {
  return gql`
    mutation {
      createRopaProcess(
        clientMutationId: "clientMutationId",
        processData: {
          type: ROPA
         ${getCreateOrUpdateProcessOrTemplateFragment(process)}
        }
      ) {
        clientMutationId
        ropaProcessId
      }
    }
  `
}

export const queryUpdateRopaTemplate = (process: RopaProcessV2) => {
  return gql`
    mutation {
      updateRopaProcess(
        clientMutationId: "clientMutationId",
        ropaProcessId: "${process.id}"
        processData: {
          templateId: "${process.templateId}"
          ${getCreateOrUpdateProcessOrTemplateFragment(process)}
        }
      ) {
        clientMutationId
        ropaProcessId
      }
    }
  `
}

export const queryCreateRopaProcess = (process: RopaProcessV2) => {
  // LA-10567: pass dataflow by default for new process
  const dataFlow: DataFlowQuestionnaire = {
    isAssigned: true
  }
  return gql`
    mutation {
      createRopaProcess(
        clientMutationId: "clientMutationId",
        processData: {
          templateId: "${process.templateId}"
          type: ROPA
          ${getCreateOrUpdateProcessOrTemplateFragment(process, dataFlow)}
        }
      ) {
        clientMutationId
        ropaProcessId
      }
    }
  `
}

export const queryUpdateCollaborationTicket = ({
  process,
  status
}: UpdateCollaborationTicketParams) => {
  return gql`
    mutation {
      ropaSendCollaborationResponse(
        clientMutationId: "clientMutationId",
        collaboratorId: "${process.ticketId}"
        responseData: {
          status: ${status}
          ropaTopics: {
            ${getQuestionnaireFragment({ process })}
          }
        }
      ) {
        clientMutationId
      }
    }
  `
}

const getDatasourceDataPropertiesFragment = (dataProperties?: DatasourceDataProperty[]) => {
  if (!dataProperties || !dataProperties.length) return ''
  return dataProperties
    .map(
      (property) => `
  {
    fieldType: ${property.fieldType}
    value: "${property.value}"
  }
  `
    )
    .join()
}
export const queryBulkCreateOrUpdateDatasourceDataProperty = (
  dataProperties: DatasourceDataProperty[]
) => {
  return gql`
    mutation {
      bulkCreateUpdateDatasourceDataProperty(
        clientMutationId: "clientMutationId",
        datasourceDataPropertyFieldData: [${getDatasourceDataPropertiesFragment(dataProperties)}]
      ) {
        clientMutationId
        datasourceDataPropertyFieldIds
      }
    }
  `
}

export const mapBulkCreateOrUpdateDatasourceDataProperty = (raw: any, fieldType?) => {
  const { datasourceDataPropertyFieldIds } = raw.bulkCreateUpdateDatasourceDataProperty
  return { ids: datasourceDataPropertyFieldIds, fieldType }
}

export const queryCreateRetentionPeriod = (period: RetentionPeriod) => {
  return gql`
    mutation {
      createRetentionPeriod(
        clientMutationId: "clientMutationId",
        retentionPeriodData: {
          durationCount: ${period.durationCount}
          durationType: ${period.durationType}
          triggerEvent: "${period.triggerEvent}"
          attributeSetIds: ${stringify(period.attributeSets || [])}
        }
      ) {
        clientMutationId
        retentionPeriod {
          edges {
            node {
              id
              durationType
              durationCount
              triggerEvent
              attributeSet {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapRetentionPeriod = (raw: any) => {
  const {
    retentionPeriod: { edges }
  } = raw.createRetentionPeriod

  const { node = {} } = edges?.[0]
  const { id, durationCount, durationType, attributeSet = [], triggerEvent } = node
  return {
    id,
    durationCount,
    durationType,
    triggerEvent,
    attributeSets: attributeSet?.edges?.map(({ node }) => node?.id) || []
  }
}

export const mutationUpdateTicketsStatus = ({
  ids,
  status
}: {
  ids: string[]
  status: TicketStatus
}) => {
  return gql`
    mutation{
     ropaUpdateCollaboratorStatus(
      clientMutationId:"clientMutationId",
            requestData:{
              collaboratorIds: ${stringify(ids)}
              status: ${status}
            }
            ){
              clientMutationId
            }
          }
  `
}

export const FRAGMENT_TOPIC_QUESTION = gql`questions {
  count
  edges {
    node {
      question
      questionId
      questionResponse
      processQuestionId
      responseType
      isMandatory
      options
    }
  }
}`

const fieldPropertyFragment = gql`
  {
    key
    value
  }
`

const dataElementsFragment = gql`
  dataElements {
    optional
    name
    id
    description
    ${FRAGMENT_TOPIC_QUESTION}
    attributes {
      count
      edges {
        node {
          id
          name
          sensitivityLabel
        }
      }
    }
    attributeProperties ${fieldPropertyFragment}
    specialElements {
      attributeName
      sensitivity
    }

    specialElementProperties ${fieldPropertyFragment}
    lawfulBases {
      count
      edges {
        node {
          id
          value
          isDeleted
        }
      }
    }
    lawfulBasisProperties ${fieldPropertyFragment}
    associatedDatasources {
      count
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const dataSubjectsFragment = gql`
  dataSubjects {
    optional
    name
    id
    description
    ${FRAGMENT_TOPIC_QUESTION}
    dataSubjects {
      count
      edges {
        node {
          id
          value
          isDeleted
        }
      }
    }
    dataSubjectProperties ${fieldPropertyFragment}
    specialDataSubject
    specialDataSubjectProperties ${fieldPropertyFragment}
    specialDataSubjects {
      count
      edges {
        node {
          id
          value
          isDeleted
        }
      }
    }
    specialDataSubjectsProperties ${fieldPropertyFragment}
    legalBases {
      count
      edges {
        node {
          id
          value
          isDeleted
        }
      }
    }
    legalBasisProperties ${fieldPropertyFragment}
  }
`
const dataRetentionFragment = gql`
  dataRetention {
    optional
    name
    id
    description
    ${FRAGMENT_TOPIC_QUESTION}
    dataRetentionProperties ${fieldPropertyFragment}
    dataRetentionInfo {
      count
      edges {
        node {
          id
          durationType
          triggerEvent
          durationCount
          attributeSet {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

const dataSafeguardsFragment = gql`
  safeguards {
    optional
    name
    id
    description
    ${FRAGMENT_TOPIC_QUESTION}
    safeguards {
      count
      edges {
        node {
          id
          value
          isDeleted
        }
      }
    }
    safeguardProperties ${fieldPropertyFragment}
  }
`

const transfersFragment = gql`
  transfers {
    ${FRAGMENT_TOPIC_QUESTION}
    optional
    name
    id
    description
    crossBorder {
      isCrossBorder
      details {
        country
        countryProperties ${fieldPropertyFragment}
        organisation
        organisationProperties ${fieldPropertyFragment}
        email
        emailProperties ${fieldPropertyFragment}
        safeguards {
          count
          edges {
            node {
              id
            }
          }
        }
        safeguardsProperties ${fieldPropertyFragment}
      }
      crossBorderProperties ${fieldPropertyFragment}
    }
    thirdParty {
      isThirdParty
      details {
        name
        nameProperties ${fieldPropertyFragment}
        address
        addressProperties ${fieldPropertyFragment}
        email
        emailProperties ${fieldPropertyFragment}
        safeguards {
          count
          edges {
            node {
              id
            }
          }
        }
        safeguardsProperties ${fieldPropertyFragment}
      }
      thirdPartyProperties ${fieldPropertyFragment}
    }
  }
`
const dataFlowFragment = gql`
  dataFlow {
    id
    isAssigned
  }
`

const customTopicsFragment = gql`
  customTopics {
    id
    name
    description
    optional
    ${FRAGMENT_TOPIC_QUESTION}
  }

`

const processSettingsFragment = gql`
  processSettings{
    edges{
      node{
        id
        reviewDuration
        reviewDueDate
        reviewStatus
        reviewFrequency
      }
    }
  }`

const mapProcessSettings = (processSettings): RopaProcessSettings | undefined => {
  if (!processSettings) return

  const { id, reviewDueDate, reviewDuration, reviewStatus, reviewFrequency } = processSettings

  return {
    id,
    reviewDueDate,
    reviewDuration,
    reviewStatus,
    reviewFrequency,
    isDueDatePast: reviewStatus === RopaProcessReviewStatuses.reviewPastDue,
    isDueDateWarning: reviewStatus === RopaProcessReviewStatuses.reviewDue
  }
}

export const queryTicketsForProcessOrTicket = ({ id, isProcess }: AssociatedTicketsParams) => {
  const filter = `filter: [{ key: TYPE, values: ["${PrivacyFeatureType.ropa}"] },{key: ${
    isProcess ? 'ROPA_PROCESS_ID' : 'PARENT_COLLABORATOR_ID'
  }, values: ["${id}"]}]`
  return gql`{
    ropaCollaboration(sortField: CREATED_AT, ${filter}) {
      count
      edges {
        node {
          id
          emailId
          status
          name
          parentCollaboratorId
          createdAt
          updateTimestamp
          ropaTopics {
            ${dataElementsFragment}
            ${dataSubjectsFragment}
            ${dataRetentionFragment}
            ${dataSafeguardsFragment}
            ${transfersFragment}
            ${dataFlowFragment}
            ${customTopicsFragment}
          }
        }
      }
    }
  }`
}

export const queryProcessOrTemplateById = (id: string) => {
  return gql`{
    ropaProcess (id: "${id}") {
      edges {
        node {
          id
          templateId
          owner
          name
          nameProperties ${fieldPropertyFragment}
          description
          descriptionProperties ${fieldPropertyFragment}
          systemDefined
          systemTemplate
          status
          updatedAt
          processQuestions {
            count
            edges {
              node {
                question
                questionId
                options
                responseType
                isMandatory
                questionResponse
              }
            }
          }
          ${processSettingsFragment}
          controllerQuestions {
            count
            edges {
              node {
                question
                questionId
                options
                responseType
                isMandatory
                questionResponse
              }
            }
          }
          processDetails {
            creatorRole
            creatorRoleProperties ${fieldPropertyFragment}
            processingActivityDetails {
              managerType
              details {
                name
                nameProperties ${fieldPropertyFragment}
                email
                emailProperties ${fieldPropertyFragment}
                address
                addressProperties ${fieldPropertyFragment}
                contact
                contactProperties ${fieldPropertyFragment}
                roleType
              }
            }
          }
          processDetailsProperties ${fieldPropertyFragment}
          purpose {
            count
            edges {
              node {
                id
                value
                isDeleted
              }
            }
          }
          purposeProperties ${fieldPropertyFragment}
          lawfulBasis {
            count
            edges {
              node {
                id
                value
                isDeleted
              }
            }
          }
          lawfulBasisProperties ${fieldPropertyFragment}
          processGroups {
            count
            edges {
              node {
                id
                value
                isDeleted
              }
            }
          }
          processGroupsProperties ${fieldPropertyFragment}
          automatedProcessing
          automatedProcessingProperties ${fieldPropertyFragment}
          automatedProcessingDescription
          automatedProcessingDescriptionProperties ${fieldPropertyFragment}
          dpoEmail
          ropaTopics {
            ${dataElementsFragment}
            ${dataSubjectsFragment}
            ${dataRetentionFragment}
            ${dataSafeguardsFragment}
            ${transfersFragment}
            ${dataFlowFragment}
            ${customTopicsFragment}
          }
        }
      }
  }

  }`
}

export const mapQueryProcessOrTemplateById = (raw: any, details?: any): RopaProcessV2 => {
  const {
    ropaProcess: { edges }
  } = raw
  const { node = {} } = edges?.[0]

  const process: RopaProcessV2 = {
    id: node?.id,
    owner: details?.owner || node?.owner,
    templateId: node?.templateId || node?.id,
    dpoEmail: details?.dpoEmail || node?.dpoEmail,
    systemDefined: node.systemDefined,
    systemTemplate: node.systemTemplate,
    name: details?.name || node?.name,
    description: details?.description ?? node?.description,
    purpose: node?.purpose.edges?.map(({ node }) => node?.id) || [],
    lawfulBasis: node?.lawfulBasis.edges?.map(({ node }) => node?.id) || [],
    processGroups: node?.processGroups.edges?.map(({ node }) => node?.id) || [],
    automatedProcessing: node?.automatedProcessing,
    automatedProcessingDescription: node?.automatedProcessingDescription,
    updatedAt: node?.updatedAt,
    processDetails: {
      processingActivityDetails: node?.processDetails?.processingActivityDetails?.map(
        (activityDetails) => ({
          managerType: activityDetails?.managerType,
          details: activityDetails?.details?.map(({ name, email, contact, address, roleType }) => ({
            name,
            email,
            contact,
            address,
            roleType
          }))
        })
      ) || [
        {
          managerType: 'CONTROLLER',
          details: []
        }
      ]
    },
    processSettings: mapProcessSettings(
      node?.processSettings?.edges[node?.processSettings?.edges?.length - 1]?.node
    ),
    questionnaire: {
      processDetails: {
        nameProperties: node?.nameProperties,
        descriptionProperties: node?.descriptionProperties,
        processGroupsProperties: node?.processGroupsProperties,
        automatedProcessingProperties: node?.automatedProcessingProperties,
        automatedProcessingDescriptionProperties: node?.automatedProcessingDescriptionProperties,
        purposeProperties: node?.purposeProperties,
        lawfulBasisProperties: node?.lawfulBasisProperties,
        processDetailsProperties: node?.processDetailsProperties,
        optional: false,
        controllerQuestions: node?.controllerQuestions?.edges?.map((edge) => edge?.node) || [],
        processQuestions: node?.processQuestions?.edges?.map((edge) => edge?.node) || [],
        processDetailsQuestionProperties: {
          nameProperties:
            node?.processDetails?.processingActivityDetails?.[0]?.details?.[0]?.nameProperties,
          addressProperties:
            node?.processDetails?.processingActivityDetails?.[0]?.details?.[0]?.addressProperties,
          emailProperties:
            node?.processDetails?.processingActivityDetails?.[0]?.details?.[0]?.emailProperties,
          contactProperties:
            node?.processDetails?.processingActivityDetails?.[0]?.details?.[0]?.contactProperties
        }
      },
      customTopics: node?.ropaTopics?.customTopics?.map((topic) => ({
        id: topic?.id || '',
        name: topic?.name || '',
        description: topic?.description || '',
        optional: topic?.optional,
        questions: topic?.questions?.edges?.map((edge) => edge?.node) || []
      }))
    }
  }
  const { totalQuestions, totalQuestionsAnswered } = getTotalProcessDetailsQuestions(process)
  if (process?.questionnaire?.processDetails) {
    process.questionnaire.processDetails.totalQuestions = totalQuestions
    process.questionnaire.processDetails.answeredQuestions = totalQuestionsAnswered
  }

  const ownerProcessResponse = mapTopicsToQuestionnaire(process, node)
  return ownerProcessResponse
}

export const queryCollaboratorTicketDetailsById = (id: string) => {
  return gql`{
    submittedTickets: ropaCollaboration(filter:[{ key: TYPE, values: ["${PrivacyFeatureType.ropa}"] },{key: PARENT_COLLABORATOR_ID, values: ["${id}"]}]) {
      count
      edges {
        node {
          id
          emailId
          status
          name
          createdAt
          updateTimestamp
          ropaTopics {
            ${dataElementsFragment}
            ${dataSubjectsFragment}
            ${dataRetentionFragment}
            ${dataSafeguardsFragment}
            ${transfersFragment}
            ${dataFlowFragment}
            ${customTopicsFragment}
          }
        }
      }
    }
    ropaCollaboration(filter:[{ key: TYPE, values: ["${PrivacyFeatureType.ropa}"] }],id: "${id}") {
      edges {
        node {
          id
          name
          status
          emailId
          parentCollaboratorId
          ropaProcess {
            edges {
              node {
                name
                dpoEmail
                owner
                id
                templateId
                processGroups {
                  edges {
                    node {
                      id
                      value
                      isDeleted
                    }
                  }
                }
              }
            }
          }
          ropaTopics {
            ${dataElementsFragment}
            ${dataSubjectsFragment}
            ${dataRetentionFragment}
            ${dataSafeguardsFragment}
            ${transfersFragment}
            ${dataFlowFragment}
            ${customTopicsFragment}
          }
        }
      }
    }
  }`
}

export const mapQueryCollaboratorTicketDetailsById = (raw: any): RopaProcessV2 => {
  const {
    ropaCollaboration: { edges }
  } = raw
  const { node = {} } = edges?.[0]
  const {
    ropaProcess: { edges: processEdges }
  } = node
  const { node: processNode = {} } = processEdges?.[0]
  const process: RopaProcessV2 = {
    id: processNode?.id,
    ticketId: node?.id,
    parentCollaboratorId: node?.parentCollaboratorId,
    owner: processNode?.owner,
    dpoEmail: processNode?.dpoEmail,
    assignedTo: node?.emailId,
    name: processNode?.name,
    templateId: processNode?.templateId,
    processGroups: processNode?.processGroups.edges?.map(({ node }) => node?.id) || [],
    status: node?.status,
    questionnaire: {
      customTopics: node?.ropaTopics?.customTopics?.map((topic) => ({
        id: topic?.id || '',
        name: topic?.name || '',
        description: topic?.description || '',
        optional: topic?.optional,
        processQuestionId: topic?.processQuestionId || '',
        questions: topic?.questions?.edges?.map((edge) => edge?.node) || []
      }))
    }
  }
  const ownerProcessResponse = mapTopicsToQuestionnaire(process, node)
  return ownerProcessResponse
}

export const queryRopaProcessReviewSchedules = () => {
  return gql`
    {
      ropaProcess {
        edges {
          node {
            id
            processSettings {
              edges {
                node {
                  reviewFrequency
                  reviewDuration
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryRopaProcessReviewSchedules = (raw: any): RopaReviewSchedule[] => {
  try {
    const list: RopaReviewSchedule[] = []
    raw.ropaProcess?.edges?.forEach(({ node }) => {
      if (node.processSettings?.edges?.length) {
        node.processSettings?.edges.forEach(({ node }) => {
          if (
            !list.find(
              ({ reviewDuration, reviewFrequency }) =>
                reviewDuration === node.reviewDuration && reviewFrequency === node.reviewFrequency
            )
          ) {
            list.push(node)
          }
        })
      }
    })
    const sortByFrequency = (a, b) => a.reviewFrequency - b.reviewFrequency
    const dailySchedules = list
      .filter(({ reviewDuration }) => reviewDuration === 'DAY')
      .sort(sortByFrequency)
    const weeklySchedules = list
      .filter(({ reviewDuration }) => reviewDuration === 'WEEK')
      .sort(sortByFrequency)
    const monthlySchedules = list
      .filter(({ reviewDuration }) => reviewDuration === 'MONTH')
      .sort(sortByFrequency)
    const yearlySchedules = list
      .filter(({ reviewDuration }) => reviewDuration === 'YEAR')
      .sort(sortByFrequency)
    const sortedSchedules: RopaReviewSchedule[] = [
      ...dailySchedules,
      ...weeklySchedules,
      ...monthlySchedules,
      ...yearlySchedules
    ]
    return sortedSchedules
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDeleteProcesOrTemplate = (id: string) => {
  return gql`
    mutation {
      deleteRopaProcess(clientMutationId: "clientMutationId", ropaProcessId: "${id}") {
        clientMutationId
      }
    }
  `
}

export const queryAddCollaborators = ({
  ropaProcessId,
  dueDate,
  emailBody = '',
  parentCollaboratorId,
  collaboratorQuestionnaire = []
}: RopaCollaboration) => {
  const questionnaire = collaboratorQuestionnaire.map((questionnaire) => {
    const dataElementsPropertiesQuery = getDataElementsPropertiesCollaboratorFragment(
      questionnaire.questionnaireInput.dataElements
    )
    const dataSubjectsPropertiesQuery = getDataSubjectsPropertiesCollaboratorFragment(
      questionnaire.questionnaireInput.dataSubjects
    )
    const dataRetentionPropertiesQuery = getDataRetentionPropertiesCollaboratorFragment(
      questionnaire.questionnaireInput.dataRetention
    )
    const safeguardsPropertiesQuery = getSafeguardsPropertiesCollaboratorFragment(
      questionnaire.questionnaireInput.safeguards
    )
    const transfersPropertiesQuery = getTransfersPropertiesCollaboratorFragment(
      questionnaire.questionnaireInput.transfers
    )
    const dataFlowPropertiesQuery = getDataFlowFragment(questionnaire.questionnaireInput.dataFlow)

    const customTopicsQuery = getCustomTopicFragment(questionnaire.questionnaireInput.customTopics)

    return `{
      emailId: "${questionnaire.emailId}"
      questionnaireInput: {
        ${dataElementsPropertiesQuery},
        ${dataSubjectsPropertiesQuery},
        ${dataRetentionPropertiesQuery},
        ${safeguardsPropertiesQuery},
        ${transfersPropertiesQuery},
        ${dataFlowPropertiesQuery},
        ${customTopicsQuery}
      }
    }`
  })

  return gql`
    mutation {
      ropaSendCollaborationRequest(
        clientMutationId: "ropaSendCollaborationRequest"
        requestData: {
          ropaProcessId: "${ropaProcessId}"
          dueDate: "${dueDate}"
          emailBody: "${encodeURIComponent(emailBody)}"
          ${
            isObjectId(parentCollaboratorId)
              ? `parentCollaboratorId: "${parentCollaboratorId}"`
              : ''
          }
          collaboratorQuestionnaire: [
            ${questionnaire.join(',')}
          ]
        }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryUpdateCollaborator = ({
  collaboratorId,
  ropaProcessId,
  questionnaire
}: UpdateCollaborationParams) => {
  const dataElementsPropertiesQuery = getDataElementsPropertiesCollaboratorFragment(
    questionnaire.dataElements
  )
  const dataSubjectsPropertiesQuery = getDataSubjectsPropertiesCollaboratorFragment(
    questionnaire.dataSubjects
  )
  const dataRetentionPropertiesQuery = getDataRetentionPropertiesCollaboratorFragment(
    questionnaire.dataRetention
  )
  const safeguardsPropertiesQuery = getSafeguardsPropertiesCollaboratorFragment(
    questionnaire.safeguards
  )
  const transfersPropertiesQuery = getTransfersPropertiesCollaboratorFragment(
    questionnaire.transfers
  )
  const dataFlowPropertiesQuery = getDataFlowFragment(questionnaire.dataFlow)
  const customTopicsQuery = getCustomTopicFragment(questionnaire.customTopics)

  return gql`
    mutation {
      updateRopaCollaborationRequest(
        clientMutationId: "updateRopaCollaborationRequest"
        collaboratorId: "${collaboratorId}"
        requestData: {
          ropaProcessId: "${ropaProcessId}"
          questionnaire: {
            ${dataElementsPropertiesQuery},
            ${dataSubjectsPropertiesQuery},
            ${dataRetentionPropertiesQuery},
            ${safeguardsPropertiesQuery},
            ${transfersPropertiesQuery},
            ${dataFlowPropertiesQuery},
            ${customTopicsQuery}
          }
        }
      ) {
        clientMutationId
      }
    }
  `
}

export const mutationSendReminder = (params: SendReminderParams) => {
  return gql`
  mutation {
    ropaRemindCollaborationRequest(
      clientMutationId: "clientMutationId",
        collaboratorId: "${params.id}"
        emailBody: "${encodeURIComponent(params.emailBody)}"
        dueDate: "${new Date(params.dueDate).toISOString()}"
    ) {
      clientMutationId
    }
  }`
}

export const mutationRevokeAssignment = (params: { id: string }) => {
  return gql`
    mutation {
      ropaRevokeCollaborationRequest(
        clientMutationId: "clientMutationId",
        collaboratorId: "${params.id}"
      ) {
        clientMutationId
      }
    }
  `
}

export const mutationRejectTicket = (params: RejectTicketParams) => {
  return gql`
    mutation {
      ropaRejectCollaborationRequest(
        clientMutationId: "clientMutationId",
          collaboratorId: "${params.id}"
          emailBody: "${encodeURIComponent(params.emailBody)}"
      ) {
        clientMutationId
      }
    }`
}

export const mutationReassignTicket = (params: ReassignmentParams) => {
  return gql`
    mutation {
      ropaReassignCollaborationRequest(
        clientMutationId: "clientMutationId",
          collaboratorId: "${params.id}"
          emailBody: "${encodeURIComponent(params.emailBody)}"
          dueDate: "${new Date(params.dueDate).toISOString()}"
          emailId: "${params.email}"
      ) {
        clientMutationId
      }
    }
  `
}

export const deleteCollaborationRequest = (params: { id: string }) => {
  return gql`
    mutation {
      ropaDeleteCollaborationRequest(
        clientMutationId: "clientMutationId",
        collaboratorId: "${params.id}"
      ){
			clientMutationId
      }
    }
  `
}

export const sendRopaNewCollaborationRequest = (params: SendReminderParams) => {
  return gql`
    mutation {
      ropaSendNewTicket(
        clientMutationId: "clientMutationId",
        collaboratorId: "${params.id}"
        dueDate: "${new Date(params.dueDate).toISOString()}"
        emailBody: "${encodeURIComponent(params.emailBody)}"
      ){
			clientMutationId
      }
    }
  `
}

export const mutationUpdateProcessSettings = (params: RopaProcessSettingsParams) => {
  const { processId, duration, frequency } = params

  const durationFragment = duration ? `duration: ${duration},` : ''
  const frequencyFragment = frequency ? `frequency: ${frequency}` : ''

  return gql`
    mutation{
      sendRopaReviewReminder(
        clientMutationId: "test"
        ropaProcessId: "${processId}"
        schedulerDetails: { ${durationFragment} ${frequencyFragment} }
      ){
        clientMutationId
        ropaProcessId
      }
    }
  `
}

// Data flow queries
export const queryRopaDataFlow = (params: RopaDataFlowParams) => {
  const { processId, topicId } = params
  return gql`
    {
      ropaDataFlow(
        filter: [
          { key: ROPA_PROCESS_ID, values: ["${processId}"] },
          { key: ROPA_TOPIC_ID, values: ["${topicId}"] }
        ]
      ) {
        edges {
          node {
            id
            ropaProcessId
            ropaTopicId
            stage
            datasource {
              edges {
                node {
                  id
                  name
                  type
                }
              }
            }
            sourceDatasources {
              stage
              datasource {
                edges {
                  node {
                    id
                    name
                    type
                  }
                }
              }
            }
            targetDatasources {
              stage
              datasource {
                edges {
                  node {
                    id
                    name
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryRopaDataFlow = (raw: any): RopaDataFlowDataSource[] => {
  try {
    const dataFlowList: RopaDataFlowDataSource[] = raw.ropaDataFlow?.edges
      .map(({ node: dtfItem }) => ({
        id: dtfItem.datasource?.edges[0]?.node?.id,
        name: dtfItem.datasource?.edges[0]?.node?.name,
        type: dtfItem.datasource?.edges[0]?.node?.type,
        processingStage: dtfItem.stage,
        dataFlowId: dtfItem.id,
        ropaProcessId: dtfItem.ropaProcessId,
        ropaTopicId: dtfItem.ropaTopicId,
        isValid: true,
        sources: dtfItem.sourceDatasources?.map((ds) => ({
          id: ds.datasource?.edges[0]?.node?.id,
          name: ds.datasource?.edges[0]?.node?.name,
          type: ds.datasource?.edges[0]?.node?.type,
          processingStage: ds.stage,
          isValid: true
        })),
        targets: dtfItem.targetDatasources?.map((ds) => ({
          id: ds.datasource?.edges[0]?.node?.id,
          name: ds.datasource?.edges[0]?.node?.name,
          type: ds.datasource?.edges[0]?.node?.type,
          processingStage: ds.stage,
          isValid: true
        }))
      }))
      .filter((item) => !!item.dataFlowId && item.id)

    return dataFlowList
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mutationSaveRopaDataFlow = (params: RopaDataFlowSaveParams) => {
  const { dataSources, processId, topicId } = params
  const topicIdFragment = topicId ? `ropaTopicId: "${topicId}"` : ''

  const mapDsToApi = (ds: RopaDataFlowDataSource, fragments = '') => {
    const dataFlowId = ds.dataFlowId ? `dataFlowId : "${ds.dataFlowId}",` : ''
    const datasourceId = ds.id ? `datasourceId : "${ds.id}",` : ''
    const stage = ds.processingStage ? `stage : ${ds.processingStage},` : ''
    return `
    {
      ${dataFlowId}
      ${datasourceId}
      ${stage}
      ${fragments}
    }`
  }

  const payload = dataSources.map((ds) => {
    let sourcesFragment = ''
    if (ds.sources?.length) {
      sourcesFragment = `sourceDatasourceDetails: [${ds.sources
        .map((source) => mapDsToApi(source))
        .join(',')}]`
    }

    let targetsFragment = ''
    if (ds.targets?.length) {
      targetsFragment = `targetDatasourceDetails:  [${ds.targets
        .map((source) => mapDsToApi(source))
        .join(',')}]`
    }

    return mapDsToApi(ds, sourcesFragment + targetsFragment)
  })

  return gql`
    mutation {
      ropaDatasourceDataFlowDetailsUpsert(
        clientMutationId: "1"
        dataFlowData: {
          ropaProcessId: "${processId}"
          ${topicIdFragment}
          datasourceDataFlowDetails: [${payload.join(',')}]
        }
      ) {
        clientMutationId
      }
    }
  `
}
export const mutationRemoveRopaDataFlow = (params: RopaDataFlowSaveParams) => {
  const { dataSources } = params

  return gql`
    mutation {
      removeDatasourceFromRopaDataFlow(
        clientMutationId: "1"
        dataFlowData: { ropaDataFlowIds: ${JSON.stringify(dataSources.map((ds) => ds.dataFlowId))} }
      ) {
        clientMutationId
      }
    }
  `
}
